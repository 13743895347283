import React from 'react'

import { useValues } from 'kea'

import StuiHeader, { IStuiHeaderProps } from 'stui/Header'

import { AppLogic } from 'app_search/App'

// We created this component as a thin wrapper around <StuiHeader> in order to
// support adding in a nessecary top-margin whenever Read-Only Mode is enabled
// and we have the sticky <ReadOnlyBanner> visible on the page
const Header: React.SFC<IStuiHeaderProps> = (props) => {
  const { readOnlyMode } = useValues(AppLogic)

  const newProps = { ...props }

  if (readOnlyMode) {
    newProps.stickyClassName = 'read-only-mode-banner-margin'
  }

  return <StuiHeader {...newProps} />
}

export default Header
