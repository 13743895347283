import React from 'react'

import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiIcon
} from '@elastic/eui'

interface IDocumentCreationButtonsProps {
  disabled?: boolean
  openDocumentCreation(creationMode: string)
}

const DocumentCreationButtons: React.SFC<IDocumentCreationButtonsProps> = ({
  disabled = false,
  openDocumentCreation
}) => (
    <EuiFlexGroup>
      <EuiFlexItem>
        {/* TODO: Eventually use EuiCard here */}
        <button
          data-test-subj="IndexingPasteJSONButton"
          className="euiCard euiCard--centerAligned euiCard--isClickable euiCard--hasIcon"
          onClick={() => openDocumentCreation('manual')}
          disabled={disabled}
        >
          <span className="euiCard__top">
            <EuiIcon type="indexEdit" className="euiCard__icon" size="xxl" color="primary" />
          </span>
          <span className="euiCard__content">
            <span className="euiTitle euiTitle--medium euiCard__title">Paste JSON</span>
          </span>
          <span className="euiCard__footer" />
        </button>
      </EuiFlexItem>
      <EuiFlexItem>
        {/* TODO: Eventually use EuiCard here */}
        <button
          className="euiCard euiCard--centerAligned euiCard--isClickable euiCard--hasIcon"
          onClick={() => openDocumentCreation('import')}
          disabled={disabled}
        >
          <span className="euiCard__top">
            <EuiIcon type="importAction" className="euiCard__icon" size="xxl" color="secondary" />
          </span>
          <span className="euiCard__content">
            <span className="euiTitle euiTitle--medium euiCard__title">Upload a JSON file</span>
          </span>
          <span className="euiCard__footer" />
        </button>
      </EuiFlexItem>
    </EuiFlexGroup>
  )

export default DocumentCreationButtons
