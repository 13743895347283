import React, { useEffect } from 'react'

import { Link, withRouter } from 'react-router-dom'

import {
  EuiSpacer,
  EuiTitle
} from '@elastic/eui'

import Header from 'app_search/Layout/Header'
import StuiMain from 'stui/Main'
import StuiSubHeading from 'stui/SubHeading'
import {
  StuiTable,
  StuiTableBody,
  StuiTableBodyCell,
  StuiTableFoot,
  StuiTableHead,
  StuiTableHeadCell,
  StuiTableRow
} from 'stui/Table'

import { Loading } from 'app_search/components/Loading'
import { IInvoice, ILMAccount, IMatch } from 'app_search/types'
import { BILLING_PATH } from 'app_search/utils/routePaths'

import { InvoiceLogic } from './InvoiceLogic'

import logoECSmallSVGPath from './assets/logo-ec--small.svg'

interface IInvoiceProps {
  actions: {
    initializeData(invoiceId: string)
  }
  dataLoading: boolean
  invoice: IInvoice
  invoiceId: string
  account: ILMAccount
  match: IMatch
}

const Invoice: React.SFC<IInvoiceProps> = ({
  actions: {
    initializeData
  },
  dataLoading,
  invoice,
  match: {
    params: {
      invoiceId
    }
  },
  account
}) => {

  useEffect(() => initializeData(invoiceId), [])

  if (dataLoading) { return <Loading /> }

  return (
    <div data-test-subj="Invoice">
      <Header viewHeader={true}>
        <div className="o-stui-breadcrumbs">
          <Link to={BILLING_PATH} className="o-stui-breadcrumb">← Return to Billing</Link>
        </div>
      </Header>
      <StuiMain>
        <div className="c-stui-invoice">
          <div className="c-stui-invoice__header">
            <div className="c-stui-invoice-branding">
              <img
                src={logoECSmallSVGPath}
              />
              <StuiSubHeading>
                <EuiTitle size="m">
                  <h2>Invoice #{invoice.displayId}</h2>
                </EuiTitle>
              </StuiSubHeading>
            </div>
            <EuiSpacer size="xl" />
            <div className="c-stui-invoice-info">
              <div className="c-stui-invoice-info-item">
                <div className="c-stui-invoice-info-item__label">Prepared for</div>
                <div className="c-stui-invoice-info-item__content">{account.displayName}</div>
              </div>
              <div className="c-stui-invoice-info-item">
                <div className="c-stui-invoice-info-item__label">Period</div>
                <div className="c-stui-invoice-info-item__content">{invoice.name}</div>
              </div>
              <div className="c-stui-invoice-info-item">
                <div className="c-stui-invoice-info-item__label">Status</div>
                <div className="c-stui-invoice-info-item__content">{invoice.humanizedState}</div>
              </div>
            </div>
          </div>
          <div className="c-stui-invoice__body">
            <StuiTable className="c-stui-invoice-summary">
              <StuiTableHead>
                <StuiTableRow>
                  <StuiTableHeadCell>Item</StuiTableHeadCell>
                  <StuiTableHeadCell>Amount</StuiTableHeadCell>
                </StuiTableRow>
              </StuiTableHead>
              <StuiTableBody>
                {invoice.lineItems && invoice.lineItems.map(({ description, amountInDollars }, index) => (
                  <StuiTableRow key={index}>
                    <StuiTableBodyCell>{description}</StuiTableBodyCell>
                    <StuiTableBodyCell><strong>{amountInDollars}</strong></StuiTableBodyCell>
                  </StuiTableRow>
                ))}
                {invoice.discounted &&
                  <StuiTableRow>
                    <StuiTableBodyCell>Discount: {invoice.stripeCouponId}</StuiTableBodyCell>
                    <StuiTableBodyCell>{invoice.discount}</StuiTableBodyCell>
                  </StuiTableRow>
                }
              </StuiTableBody>
              <StuiTableFoot>
                <StuiTableRow>
                  <StuiTableBodyCell colspan={2} className="c-stui-invoice-total">
                    <span className="c-stui-invoice-total__label">Total</span>
                    <span className="c-stui-invoice-total__amount">{invoice.totalDueInDollars}</span>
                  </StuiTableBodyCell>
                </StuiTableRow>
              </StuiTableFoot>
            </StuiTable>
          </div>
          <div className="c-stui-invoice__footer">
            <div className="c-stui-invoice-emoji">☺</div>
            <div>Thank you for your business</div>
          </div>
        </div>
      </StuiMain>
    </div>
  )
}

export default withRouter(InvoiceLogic(Invoice))
