import React from 'react'

import {
  EuiButton,
  EuiModalBody,
  EuiModalFooter,
  EuiSpacer
} from '@elastic/eui'

import Header from 'app_search/Layout/Header'
import StuiHeading from 'stui/Heading'

interface IDocumentCreationErrorProps {
  buttonTitle: string
  errors: string[]
  buttonClick()
}

const DocumentCreationError: React.SFC<IDocumentCreationErrorProps> = ({
  errors,
  buttonTitle,
  buttonClick
}) => (
    <>
      <EuiModalBody>
        <Header>
          <StuiHeading type="section">Unable to index documents.</StuiHeading>
        </Header>
        <EuiSpacer />
        <>{errors.map((errorMessage, errorIndex) => <p key={errorIndex}>{errorMessage}</p>)}</>
      </EuiModalBody>
      {buttonTitle && buttonClick && <EuiModalFooter>
        <EuiButton onClick={buttonClick} fill={true}>{buttonTitle}</EuiButton>
      </EuiModalFooter>}
    </>
  )

export default DocumentCreationError
