import React from 'react'

import { EuiSpacer } from '@elastic/eui'

import Header from 'app_search/Layout/Header'
import StuiHeading from 'stui/Heading'
import {
  StuiTable,
  StuiTableBody,
  StuiTableControls,
  StuiTableControlsGroup,
  StuiTableHead,
  StuiTableHeadCell,
  StuiTableRow
} from 'stui/Table'

import SchemaConflictedField from './SchemaConflictedField'
import SchemaMetaFieldCell from './SchemaMetaFieldCell'

import { IObject } from 'app_search/types'

interface ISchemaConflictedFieldsProps {
  conflictingFields: IObject
}

const SchemaConflictedFields: React.SFC<ISchemaConflictedFieldsProps> = ({ conflictingFields }) => (
  <div className="c-stui-engine-schema-fields c-stui-engine-schema-fields--conflicted">
    <StuiTableControls>
      <StuiTableControlsGroup>
        <Header>
          <StuiHeading type="section">Inactive Fields</StuiHeading>
          <p>These fields have type conflicts. To activate these fields, change types in the Source Engines to match.</p>
        </Header>
      </StuiTableControlsGroup>
    </StuiTableControls>

    <StuiTable>
      <StuiTableHead>
        <StuiTableRow>
          <StuiTableHeadCell>Field</StuiTableHeadCell>
          <StuiTableHeadCell>Type Conflict</StuiTableHeadCell>
        </StuiTableRow>
      </StuiTableHead>
      <StuiTableBody>
        {Object.keys(conflictingFields).map(fieldName => {
          const conflictingField = conflictingFields[fieldName]
          const fieldTypes = Object.keys(conflictingField)
          return (
            <StuiTableRow key={fieldName}>
              <SchemaMetaFieldCell isFieldName={true}>{fieldName}</SchemaMetaFieldCell>
              <SchemaMetaFieldCell>
                <div className="c-stui-engine-schema-field__conflicts">
                  {fieldTypes.map((type, i) => (
                    <SchemaConflictedField key={i} fieldType={type} engines={conflictingField[type]} />
                  ))}
                </div>
              </SchemaMetaFieldCell>
            </StuiTableRow>
          )
        })}
      </StuiTableBody>
    </StuiTable>
    <EuiSpacer />
  </div>
)

export default SchemaConflictedFields
