import React, { useEffect } from 'react'

import { withRouter } from 'react-router-dom'

import {
  ENGINE_ANALYTICS_RECENT_QUERIES_PATH,
  ENGINE_ANALYTICS_TOP_QUERIES_NO_CLICKS_PATH,
  ENGINE_ANALYTICS_TOP_QUERIES_NO_RESULTS_PATH,
  ENGINE_ANALYTICS_TOP_QUERIES_PATH,
  ENGINE_ANALYTICS_TOP_QUERIES_WITH_CLICKS_PATH
} from 'app_search/utils/routePaths'

import { AnalyticsLogic } from './AnalyticsLogic'

import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiSpacer
} from '@elastic/eui'

import { Loading } from 'app_search/components/Loading'
import Header from 'app_search/Layout/Header'
import StuiFlashMessages, { IStuiFlashMessagesProps } from 'stui/FlashMessages'
import StuiHeading from 'stui/Heading'
import StuiMain from 'stui/Main'

import { IAnalyticsData, IObject, IRouter } from 'app_search/types'

import AnalyticsChart from './AnalyticsChart'
import AnalyticsHeaderActions from './AnalyticsHeaderActions'
import AnalyticsMetricCard from './AnalyticsMetricCard'
import AnalyticsTableCard from './AnalyticsTableCard'
import AnalyticsUnavailable from './AnalyticsUnavailable'
import PlanLimitModal from './PlanLimitModal'
import RecentQueryTable from './RecentQueryTable'
import TopResultsTable from './TopResultsTable'

export interface IAnalyticsProps extends IAnalyticsData, IRouter {
  actions: {
    initializeAnalyticsData(history: IObject)
    setDataLoading(loading: boolean)
  }
  engineName: string
  flashMessages: IStuiFlashMessagesProps
  dataLoading: boolean
}

const Analytics: React.SFC<IAnalyticsProps> = ({
  history,
  actions: {
    initializeAnalyticsData,
    setDataLoading
  },
  engineName,
  flashMessages,
  showPlanLimitMessage,
  startDate,
  endDate,
  recentQueries,
  queriesPerDay,
  queriesNoResultsPerDay,
  clicksPerDay,
  allTags,
  currentTag,
  topQueries,
  topQueriesNoClicks,
  topQueriesNoResults,
  topQueriesWithClicks,
  totalClicks,
  totalQueries,
  totalQueriesNoResults,
  analyticsUnavailable,
  dataLoading
}) => {
  useEffect(() => {
    initializeAnalyticsData(history.location)
    return () => setDataLoading(true)
  }, [history.location.search])

  if (dataLoading) { return <Loading /> }

  const headerActions = (
    <AnalyticsHeaderActions
      allTags={allTags}
      currentTag={currentTag}
      startDate={startDate}
      endDate={endDate}
      disableClick={!!showPlanLimitMessage}
    />
  )

  const tableProps = { currentTag, startDate, endDate, engineName }

  return (
    <div data-test-subj="Analytics">
      {analyticsUnavailable ? <AnalyticsUnavailable /> :
        <>
          <Header key="header" contained={true} viewHeader={true} actions={headerActions} isSticky={true}>
            <StuiHeading type="view">Analytics</StuiHeading>
          </Header>
          <StuiMain key="main">
            {!showPlanLimitMessage && <StuiFlashMessages {...flashMessages} />}
            {showPlanLimitMessage && <PlanLimitModal />}

            <EuiFlexGroup>
              <EuiFlexItem data-test-subj="TotalQueriesCard">
                <AnalyticsMetricCard title="Total Queries" value={totalQueries} />
              </EuiFlexItem>
              <EuiFlexItem data-test-subj="TotalQueriesNoResultsCard">
                <AnalyticsMetricCard title="Total Queries with No Results" value={totalQueriesNoResults} />
              </EuiFlexItem>
              <EuiFlexItem data-test-subj="TotalClicksCard">
                <AnalyticsMetricCard title="Total Clicks" value={totalClicks} />
              </EuiFlexItem>
            </EuiFlexGroup>

            <EuiSpacer size="xxl" />

            <AnalyticsChart
              data={[queriesPerDay, queriesNoResultsPerDay, clicksPerDay]}
              labels={['Total Queries', 'No Result Queries', 'Clicks']}
              startDate={new Date(startDate)}
              endDate={new Date(endDate)}
            />

            <EuiSpacer size="xxl" />

            <AnalyticsTableCard
              title="Query Analytics"
              description="Gain insight into the most frequent queries, and which queries returned no results."
            >
              <EuiFlexGroup gutterSize="xl">
                <EuiFlexItem data-test-subj="AnalyticsTopQueries">
                  <Header>
                    <StuiHeading type="title">Top Queries</StuiHeading>
                  </Header>
                  <EuiSpacer />
                  <TopResultsTable
                    showClicks={true}
                    queries={topQueries}
                    viewAllPath={ENGINE_ANALYTICS_TOP_QUERIES_PATH}
                    {...tableProps}
                  />
                </EuiFlexItem>
                <EuiFlexItem data-test-subj="AnalyticsTopQueriesWithNoResults">
                  <Header>
                    <StuiHeading type="title">Top Queries with No Results</StuiHeading>
                  </Header>
                  <EuiSpacer />
                  <TopResultsTable
                    queries={topQueriesNoResults}
                    viewAllPath={ENGINE_ANALYTICS_TOP_QUERIES_NO_RESULTS_PATH}
                    {...tableProps}
                  />
                </EuiFlexItem>
              </EuiFlexGroup>
            </AnalyticsTableCard>
            <EuiSpacer size="xxl" />

            <AnalyticsTableCard
              title="Click Analytics"
              description="Discover which queries generated the most and least amount of clicks."
            >
              <EuiFlexGroup gutterSize="xl">
                <EuiFlexItem data-test-subj="AnalyticsTopQueriesWithClicks">
                  <Header>
                    <StuiHeading type="title">Top Queries with Clicks</StuiHeading>
                  </Header>
                  <EuiSpacer />
                  <TopResultsTable
                    showClicks={true}
                    queries={topQueriesWithClicks}
                    viewAllPath={ENGINE_ANALYTICS_TOP_QUERIES_WITH_CLICKS_PATH}
                    {...tableProps}
                  />
                </EuiFlexItem>
                <EuiFlexItem data-test-subj="AnalyticsTopQueriesWithNoClicks">
                  <Header>
                    <StuiHeading type="title">Top Queries with No Clicks</StuiHeading>
                  </Header>
                  <EuiSpacer />
                  <TopResultsTable queries={topQueriesNoClicks} viewAllPath={ENGINE_ANALYTICS_TOP_QUERIES_NO_CLICKS_PATH} {...tableProps} />
                </EuiFlexItem>
              </EuiFlexGroup>
            </AnalyticsTableCard>
            <EuiSpacer size="xxl" />

            <AnalyticsTableCard
              title="Recent Queries"
              description="A view into queries happening right now."
            >
              <RecentQueryTable queries={recentQueries} viewAllPath={ENGINE_ANALYTICS_RECENT_QUERIES_PATH} {...tableProps} />
            </AnalyticsTableCard>
          </StuiMain>
        </>}
    </div>
  )
}

export default withRouter(AnalyticsLogic(Analytics))
