import React from 'react'

import { withRouter } from 'react-router-dom'

import { IAccountProps, IObject, ISupportedLanguages } from 'app_search/types'

import { CreateEngineLogic } from './CreateEngineLogic'

import {
  EuiButton,
  EuiCallOut,
  EuiFieldText,
  EuiFlexGroup,
  EuiFlexItem,
  EuiForm,
  EuiFormRow,
  EuiSelect,
  EuiSpacer
} from '@elastic/eui'

import Header from 'app_search/Layout/Header'
import StuiFlashMessages, { IStuiFlashMessagesProps } from 'stui/FlashMessages'
import StuiHeading from 'stui/Heading'
import StuiMain from 'stui/Main'

const ALLOWED_CHARS_NOTE = 'Engine names can only contain lowercase letters, numbers, and hyphens'

interface ICreateEngineProps {
  history: IObject
  actions: {
    setCreateEngineData({ account, supportedLanguages })
    setLanguage(language: string)
    setRawName(rawName: string)
    submitEngine(name: string, language: string, history: IObject)
  }
  flashMessages: IStuiFlashMessagesProps
  language: string
  name: string
  rawName: string
  account: IAccountProps
  supportedLanguages: ISupportedLanguages[]
}

interface ICreateEngineContentProps {
  account: IAccountProps
}

const CreateEngineContent: React.SFC<ICreateEngineContentProps> = ({ account }) => {
  const createEngineButton = (color) => <EuiButton color={color} type="submit" data-test-subj="NewEngineSubmitButton" fill={true}>Create Engine</EuiButton>

  const createEngineCalloutContent = (calloutType, buttonColor, headingMessage, sectionMessage) => (
    <EuiCallOut
      title={headingMessage}
      color={calloutType}
    >
      <p>{sectionMessage}</p>
      <EuiSpacer size="s" />
      {createEngineButton(buttonColor)}
    </EuiCallOut>
  )

  let createEngineButtonContent = createEngineButton('secondary')
  if (account.metered && !account.freeTrial) {
    if (account.numRemainingEngines > 0) {
      createEngineButtonContent = createEngineCalloutContent(
        'secondary',
        'secondary',
        `You have ${account.numRemainingEngines} available Engine${account.numRemainingEngines > 1 ? 's' : ''}.`,
        'No additional fees will appear within your bill.'
      )
    } else {
      createEngineButtonContent = createEngineCalloutContent(
        'warning',
        'warning',
        'You have reached your Engine limit.',
        `A monthly charge of $${account.usdPricePerEngine} will begin once you create this Engine.`
      )
    }
  }

  return createEngineButtonContent
}

const CreateEngine: React.SFC<ICreateEngineProps> = ({
  actions: {
    setLanguage,
    setRawName,
    submitEngine
  },
  history,
  flashMessages,
  language,
  name,
  rawName,
  account,
  supportedLanguages
}) => {
  const engineNameNote = () => rawName !== name ? <>Your engine will be named <strong>{name}</strong></> : ALLOWED_CHARS_NOTE
  const handleSubmit = e => {
    e.preventDefault()
    submitEngine(name, language, history)
  }

  return (
    <div>
      <Header contained={true} viewHeader={true}>
        <StuiHeading type="view">Create an Engine</StuiHeading>
      </Header>
      <StuiMain>
        <StuiFlashMessages {...flashMessages} />
        <div className="o-stui-layout-container--narrow">
          <div className="o-stui-card">
            <EuiForm>
              <form onSubmit={handleSubmit}>
                <Header>
                  <StuiHeading type="sub">Name your Engine</StuiHeading>
                </Header>
                <EuiSpacer />
                <EuiFlexGroup>
                  <EuiFlexItem>
                    <EuiFormRow
                      label="Engine name"
                      helpText={engineNameNote()}
                      fullWidth={true}
                    >
                      <EuiFieldText
                        name="engine-name"
                        value={rawName}
                        onChange={event => setRawName(event.currentTarget.value)}
                        autoComplete="off"
                        fullWidth={true}
                        data-test-subj="NewEngineNameInput"
                        placeholder="i.e., my-search-engine"
                        autoFocus={true}
                      />
                    </EuiFormRow>
                  </EuiFlexItem>
                  <EuiFlexItem grow={false}>
                    <EuiFormRow
                      label="Language"
                    >
                      <EuiSelect
                        name="engine-language"
                        value={language}
                        options={supportedLanguages}
                        onChange={event => setLanguage(event.target.value)}
                      />
                    </EuiFormRow>
                  </EuiFlexItem>
                </EuiFlexGroup>
                <EuiSpacer />
                <CreateEngineContent account={account} />
              </form>
            </EuiForm>
          </div>
        </div>
      </StuiMain>
    </div>
  )
}

export default withRouter(CreateEngineLogic(CreateEngine))
