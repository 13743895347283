import React, { useEffect } from 'react'

import { Link } from 'react-router-dom'

import { Loading } from 'app_search/components/Loading'
import { BILLING_PATH } from 'app_search/utils/routePaths'

import { IApiLog, IApiLogsMeta, IObject } from 'app_search/types'
import { LOW_METERED, PLAN_DESCRIPTIONS } from 'shared/constants/plans'

import {
  EuiCallOut,
  EuiSpacer
} from '@elastic/eui'

import Header from 'app_search/Layout/Header'
import StuiEmptyState, { IStuiEmptyStateProps } from 'stui/EmptyState'
import StuiFlashMessages, { IStuiFlashMessagesProps } from 'stui/FlashMessages'
import StuiHeading from 'stui/Heading'
import StuiMain from 'stui/Main'

import ApiLogDetail from './ApiLogDetail'
import { ApiLogsLogic } from './ApiLogsLogic'
import ApiLogsTable from './ApiLogsTable'

interface IApiLogsProps {
  actions: {
    closeDetails()
    initializeData()
    setActiveApiLog()
  }
  logsUnavailable: boolean
  dataLoading: boolean
  logs: IApiLog[]
  logsMeta: IApiLogsMeta
  account: IObject
  activeApiLog?: IApiLog
  emptyStateProps: IStuiEmptyStateProps
  showPlanLimitCallout: boolean
  flashMessages: IStuiFlashMessagesProps
}

const ApiLogs: React.SFC<IApiLogsProps> = props => {
  const {
    actions: { closeDetails, initializeData, setActiveApiLog },
    logs,
    logsUnavailable,
    account,
    activeApiLog,
    dataLoading,
    emptyStateProps,
    showPlanLimitCallout,
    flashMessages
  } = props
  useEffect(() => {
    initializeData()
  }, [])

  if (dataLoading) { return <Loading /> }

  const tableContent = logsUnavailable ? (
    <StuiEmptyState
      icon="logs-fill"
      title="Logs are currently unavailable"
      description="Please try again in a few minutes"
    />
  ) : (
      <div className="api-logs-table-container">
        {logs.length > 0 ?
          <ApiLogsTable setActiveApiLog={setActiveApiLog} {...props} /> :
          <StuiEmptyState {...emptyStateProps} />
        }
      </div>
    )

  const planLimitCallout = () => {
    if (!showPlanLimitCallout || account.currentPlan !== PLAN_DESCRIPTIONS[LOW_METERED]) {
      return null
    }

    const timePeriod = account.apiLogRetentionDays === 1 ? '24 hours' : `${account.apiLogRetentionDays} days`

    return (
      <>
        <EuiSpacer />
        <EuiCallOut
          iconType="iInCircle"
          title={<><strong>{account.currentPlan} plans</strong> include logs for the last <strong>{timePeriod}</strong> of API events.</>}
        >
          <p>Ready to go deeper? Consider <Link to={BILLING_PATH}>upgrading your account</Link>.</p>
        </EuiCallOut>
        <EuiSpacer />
      </>
    )
  }

  return <div data-test-subj="APILogs">
    <Header key="header" contained={true} viewHeader={true} isSticky={true}>
      <StuiHeading type="view">API Logs</StuiHeading>
    </Header>
    <StuiMain key="content">
      {!!flashMessages && <StuiFlashMessages {...flashMessages} />}
      {tableContent}
      {planLimitCallout()}
      {activeApiLog && <ApiLogDetail onClose={closeDetails} log={activeApiLog} />}
    </StuiMain>
  </div>
}

export default ApiLogsLogic(ApiLogs)
