import React from 'react'

import classNames from 'classnames'
import Sticky from 'react-stickynode'

import StuiIcon from 'stui/Icon'

export interface IStuiHeaderProps {
  children: React.ReactNode
  actions?: React.ReactNode
  icon?: string
  className?: string
  keyline?: boolean
  contained?: boolean
  viewHeader?: boolean
  isSticky?: boolean
  stickyClassName?: string
}

const StuiHeader: React.SFC<IStuiHeaderProps> = ({
  children,
  icon,
  className,
  keyline,
  contained,
  viewHeader,
  actions,
  isSticky,
  stickyClassName
}) => {
  const cssClass = classNames(
    'c-stui-header',
    className,
    { 'c-stui-view-header': viewHeader },
    { 'c-stui-header--with-keyline': keyline },
    { 'c-stui-header--with-icon': !!icon }
  )

  const header = <header className={cssClass}>
    {contained || icon ?
      <div className="c-stui-header__container">
        <div className="c-stui-header__content">
          {icon &&
            <div className="c-stui-header__icon">
              <StuiIcon name={icon} />
            </div>
          }
          {children}
        </div>
        {actions && <div className="c-stui-header__actions">{actions}</div>}
      </div> :
      <div className="c-stui-header__content">{children}</div>}
  </header>

  return (
    isSticky ? <Sticky enabled={true} activeClass={classNames('c-stui-view-header--fixed', stickyClassName)}>{header}</Sticky> : header
  )
}

export default StuiHeader
