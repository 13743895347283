import moment from 'moment'
import React from 'react'

import { IObject } from 'app_search/types'

import {
  EuiFlexItem,
  EuiFormRow,
  EuiPanel,
  EuiProgress,
  EuiSpacer
} from '@elastic/eui'

import Header from 'app_search/Layout/Header'
import StuiHeading from 'stui/Heading'

interface IBillingUsagePanelProps {
  accountBillingMeta: IObject
  accountBillingUsage: IObject
}

const BillingUsagePanel: React.SFC<IBillingUsagePanelProps> = ({ accountBillingUsage, accountBillingMeta }) => {
  const {
    documentCount,
    documentCurrentUsageMax,
    apiRequestCount,
    apiRequestCurrentUsageMax
  } = accountBillingUsage

  const {
    currentInvoice,
    plan
  } = accountBillingMeta

  const planSubscriptionPaid = plan && plan.subscription && plan.subscription.isPaid

  if (!planSubscriptionPaid || !accountBillingUsage || !currentInvoice) {
    return null
  }

  const {
    endDate,
    startDate
  } = currentInvoice

  return (
    <EuiFlexItem>
      <EuiPanel paddingSize="m">
        <Header>
          <StuiHeading type="section">Usage</StuiHeading>
          <p>{moment(startDate).format('MMMM D, YYYY')} — {moment(endDate).format('MMMM D, YYYY')}</p>
        </Header>
        <EuiSpacer />
        <EuiFormRow fullWidth={true} label="Documents" helpText={`${documentCount} of ${documentCurrentUsageMax}`}>
          <EuiProgress color="primary" size="l" value={documentCount} max={documentCurrentUsageMax} />
        </EuiFormRow>
        <EuiFormRow fullWidth={true} label="API Operations" helpText={`${apiRequestCount} of ${apiRequestCurrentUsageMax}`}>
          <EuiProgress color="secondary" size="l" value={apiRequestCount} max={apiRequestCurrentUsageMax} />
        </EuiFormRow>
      </EuiPanel>
    </EuiFlexItem>
  )
}

export default BillingUsagePanel
