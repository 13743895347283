import React from 'react'

import moment from 'moment'

import { IObject } from 'app_search/types'

import {
  EuiFlexItem,
  EuiPanel,
  EuiSpacer
} from '@elastic/eui'

import Header from 'app_search/Layout/Header'
import StuiHeading from 'stui/Heading'

interface IBillingInformationPanelProps {
  accountBillingMeta: IObject
  accountBillingUsage: IObject
}

function numberToCurrency(numberValue) {
  return `\$${numberValue.toFixed(2)}`
}

const BillingInformationPanel: React.SFC<IBillingInformationPanelProps> = ({ accountBillingMeta, accountBillingUsage }) => {
  const {
    plan
  } = accountBillingMeta
  const {
    costPerBillingIntervalInDollars,
    estimatedInvoiceBillingDate,
    forecastedBillTotalCost
  } = accountBillingUsage
  const planSubscriptionPaid = plan && plan.subscription && plan.subscription.isPaid

  if (!accountBillingUsage || !plan || !planSubscriptionPaid) {
    return null
  }

  return (
    <EuiFlexItem>
      <EuiPanel paddingSize="m">
        <Header>
          <StuiHeading type="section">Next Billing Event</StuiHeading>
          <p>For the period ending {moment(estimatedInvoiceBillingDate).format('MMMM D, YYYY')}</p>
        </Header>
        <EuiSpacer />
        <div className="billing-summary">
          <div className="billing-summary-plan">
            <div className="billing-summary-plan__content">
              <div className="billing-summary-plan__title">{plan.description} plan monthly fee</div>
            </div>
            <div className="billing-summary-plan__price">{numberToCurrency(costPerBillingIntervalInDollars)}</div>
          </div>
          {accountBillingUsage.forecastedBillingItems.map((item, itemIndex) => (
            <div key={itemIndex} className="billing-summary-line-item">
              <div className="billing-summary-line-item__content">
                <div className="billing-summary-line-item__title">{item.description}</div>
                <div className="billing-summary-line-item__description">{item.subDescription}</div>
              </div>
              <div className="billing-summary-item__price">{numberToCurrency(item.amountInDollars)}</div>
            </div>
          ))}
          <div className="billing-summary-total">
            <div className="billing-summary-total__content">
              <div className="billing-summary-total__title">Estimated Total</div>
              <div className="billing-summary-total__description">To be paid {estimatedInvoiceBillingDate}</div>
            </div>
            <div className="billing-summary-total__price">{numberToCurrency(forecastedBillTotalCost)}</div>
          </div>
        </div>
      </EuiPanel>
    </EuiFlexItem>
  )
}

export default BillingInformationPanel
