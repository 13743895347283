import React from 'react'

import { EuiSpacer } from '@elastic/eui'

import Header from 'app_search/Layout/Header'
import StuiHeading from 'stui/Heading'

interface IAnalyticsTableCardProps {
  title: string
  description: string
}

const AnalyticsTableCard: React.SFC<IAnalyticsTableCardProps> = ({ title, description, children }) => (
  <div className="o-stui-card">
    <Header>
      <StuiHeading type="section">{title}</StuiHeading>
      <p>{description}</p>
    </Header>
    <EuiSpacer />
    {children}
  </div>
)

export default AnalyticsTableCard
