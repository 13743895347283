import React from 'react'

import { useValues } from 'kea'

import { EngineLogic } from 'app_search/Engine'

import Header from 'app_search/Layout/Header'
import StuiHeading from 'stui/Heading'
import StuiMain from 'stui/Main'

import QueryTesterSearch from './QueryTesterSearch'

const QueryTester: React.SFC<{}> = _ => {
  const { engine: { name: engineName, schema, result_fields: resultFields } } = useValues(EngineLogic)
  return (
    <div data-test-subj="QueryTester">
      <Header key="header" contained={true} viewHeader={true} isSticky={true}>
        <StuiHeading type="view">Query Tester</StuiHeading>
      </Header>
      <StuiMain key="main">
        <QueryTesterSearch
          resultFields={resultFields}
          engineName={engineName}
          schema={schema}
        />
      </StuiMain>
    </div>
  )
}

export default QueryTester
