import React from 'react'

import Header from 'app_search/Layout/Header'
import StuiHeading from 'stui/Heading'

interface ICurationsTableHeaderProps {
  icon: string
  title: string | React.ReactNode
  subtitle?: string
  actions?: React.ReactNode
}

const CurationsTableHeader: React.SFC<ICurationsTableHeaderProps> = ({ icon, title, subtitle, actions }) => (
  <Header
    className="curations-table-header"
    icon={icon}
    actions={actions}
  >
    <StuiHeading type="title">{title}</StuiHeading>
    {subtitle && <p className="c-stui-header__subtitle">{subtitle}</p>}
  </Header>
)

export default CurationsTableHeader
