import routes from 'app_search/routes'
import {
  IInvitation,
  IObject,
  IRole,
  IUser,
  TRole
} from 'app_search/types'
import { splitSeparatedValue } from 'app_search/utils'
import handleAPIError from 'app_search/utils/handleAPIError'
import http from 'shared/http'
import { storeLogic } from 'shared/store'
import { IStuiFlashMessagesProps } from 'stui/FlashMessages'
import { FlashMessagesLogic } from './FlashMessagesLogic'

interface IUsersData {
  defaultInvitationRoleType: TRole
  demo: boolean
  invitations: IInvitation[]
  invitationsEmptyState: string
  myRole: IRole
  showInvitationLink: boolean
  users: IUser[]
}

const defaultUsersData: IUsersData = {
  defaultInvitationRoleType: 'finance',
  demo: false,
  invitations: [],
  invitationsEmptyState: '',
  myRole: {} as IRole,
  showInvitationLink: false,
  users: []
}

export interface IUsersActions {
  clearFlashMessages()
  handleInviteButtonSubmit(event: React.SyntheticEvent)
  handleLTGInviteButtonSubmit(event: React.SyntheticEvent)
  handleResendInvitation(event: React.SyntheticEvent, code: string)
  handleRevokeInvitation(event: React.SyntheticEvent, code: string)
  initializeUsersData()
  loadQueuedMessages()
  setLoadingOnExit()
  setEmails(emails: string)
  setFlashMessages(flashMessages: IStuiFlashMessagesProps)
  setInvitationRoleType(invitationRoleType: TRole)
  setShowInvitationsModal(showInvitationsModal: boolean)
  setUsername(username: string)
  setUsersData(usersData: IObject)
  setUsersUXState(state: IObject)
  toggleInvitationsModal()
  onInviteButtonSubmit(usersData: IObject)
  onLTGInviteSubmit(usersData: IObject)
  onResendInvitation(usersData: IObject)
  onRevokeInvitation(usersData: IObject)
}

export interface IUsersValues {
  dataLoading: boolean
  emails: string
  invitationRoleType: TRole
  showInvitationsModal: boolean
  username: string
  usersData: IUsersData
}

export const UsersLogic = storeLogic({
  connect: () => ({
    actions: [
      FlashMessagesLogic, ['setFlashMessages']
    ]
  }),
  actions: () => ({
    onInviteButtonSubmit: (usersData: IUsersData) => ({ usersData }),
    onLTGInviteSubmit: (usersData: IUsersData) => ({ usersData }),
    onResendInvitation: (usersData: IUsersData) => ({ usersData }),
    onRevokeInvitation: (usersData: IUsersData) => ({ usersData }),
    setEmails: (emails: string) => ({ emails }),
    setInvitationRoleType: (invitationRoleType: TRole) => ({ invitationRoleType }),
    setLoadingOnExit: () => true,
    setShowInvitationsModal: (showInvitationsModal: boolean) => ({ showInvitationsModal }),
    setUsername: (username: string) => ({ username }),
    setUsersData: (usersData: IUsersData) => usersData
  }),
  reducers: ({ actions }) => ({
    dataLoading: [true, {
      [actions.setUsersData]: () => false,
      [actions.setLoadingOnExit]: () => true
    }],
    emails: ['', {
      [actions.onInviteButtonSubmit]: () => '',
      [actions.onLTGInviteSubmit]: () => '',
      [actions.setEmails]: (_, { emails }) => emails
    }],
    invitationRoleType: ['', {
      [actions.setUsersData]: (_, { defaultInvitationRoleType }) => defaultInvitationRoleType,
      [actions.setInvitationRoleType]: (_, { invitationRoleType }) => invitationRoleType
    }],
    showInvitationsModal: [false, {
      [actions.setShowInvitationsModal]: (_, { showInvitationsModal }) => showInvitationsModal
    }],
    username: ['', {
      [actions.onLTGInviteSubmit]: () => '',
      [actions.setUsername]: (_, { username }) => username
    }],
    usersData: [defaultUsersData, {
      [actions.onInviteButtonSubmit]: (oldUsersData, { usersData }) => ({ ...oldUsersData, ...usersData }),
      [actions.onLTGInviteSubmit]: (oldUsersData, { usersData }) => ({ ...oldUsersData, ...usersData }),
      [actions.onResendInvitation]: (oldUsersData, { usersData }) => ({ ...oldUsersData, ...usersData }),
      [actions.onRevokeInvitation]: (oldUsersData, { usersData }) => ({ ...oldUsersData, ...usersData }),
      [actions.setUsersData]: (oldUsersData, usersData) => ({ ...oldUsersData, ...usersData })
    }]
  }),
  thunks: (
    {
      actions,
      values
    }: { actions: IUsersActions, values: IUsersValues }
  ) => ({
    initializeUsersData: () => {
      http(routes.locoMocoUsersPath())
        .then(({ data }: { data: IUsersData }) => {
          actions.setUsersData(data)
          // actions.clearFlashMessages()
        })
        .catch(handleAPIError(messages => actions.setFlashMessages({ error: messages })))
    },
    handleResendInvitation: (event: React.SyntheticEvent, code: string) => {
      const {
        usersData
      } = values

      event.preventDefault()
      if (usersData.demo) { return }
      http({
        method: 'post',
        url: routes.resendLocoMocoInvitationPath(code)
      })
        .then(({ data }) => {
          const newUsersData = {
            invitations: data.invitations
          }
          const flashMessages = {
            success: data.success,
            error: data.errors
          }
          actions.onResendInvitation(newUsersData)
          actions.setFlashMessages(flashMessages)
        })
        .catch(handleAPIError(messages => actions.setFlashMessages({ error: messages })))
    },
    toggleInvitationsModal: () => {
      const {
        showInvitationsModal,
        usersData
      } = values

      if (usersData.demo) { return }
      actions.setShowInvitationsModal(!showInvitationsModal)
    },
    handleRevokeInvitation: (event: React.SyntheticEvent, code: string) => {
      const {
        usersData
      } = values

      event.preventDefault()
      if (usersData.demo) { return }
      http({
        method: 'delete',
        url: routes.locoMocoInvitationPath(code)
      })
        .then(({ data }) => {
          const newUsersData = {
            invitations: data.invitations
          }
          const flashMessages = {
            success: data.success,
            error: data.errors
          }
          actions.onRevokeInvitation(newUsersData)
          actions.setFlashMessages(flashMessages)
        })
        .catch(handleAPIError(messages => actions.setFlashMessages({ error: messages })))
    },
    handleInviteButtonSubmit: (event: React.SyntheticEvent) => {
      const {
        emails,
        invitationRoleType,
        usersData
      } = values

      event.preventDefault()
      if (usersData.demo) { return }
      http({
        data: {
          emails: Array.from(new Set(splitSeparatedValue(emails))),
          role_type: invitationRoleType
        },
        method: 'post',
        url: routes.locoMocoInvitationsPath()
      })
        .then(({ data }) => {
          const newUsersData = {
            users: data.users,
            invitations: data.invitations,
            myRole: data.myRole
          }
          const flashMessages = {
            success: data.success,
            error: data.errors
          }
          actions.onInviteButtonSubmit(newUsersData)
          actions.setFlashMessages(flashMessages)
        })
        .catch(handleAPIError(messages => actions.setFlashMessages({ error: messages })))
        .then(() => {
          actions.toggleInvitationsModal()
        })
    },
    handleLTGInviteButtonSubmit: (event: React.SyntheticEvent) => {
      const {
        emails,
        invitationRoleType,
        username,
        usersData
      } = values

      event.preventDefault()
      if (usersData.demo) { return }
      http({
        data: {
          email: emails.trim(),
          username: username.trim(),
          role_type: invitationRoleType
        },
        method: 'post',
        url: routes.locoMocoInvitationsPath()
      })
        .then(({ data }) => {
          const newUsersData = {
            emails: '',
            username: '',
            users: data.users,
            invitations: data.invitations,
            myRole: data.myRole
          }
          const flashMessages = {
            success: data.success,
            error: data.errors
          }
          actions.onLTGInviteSubmit(newUsersData)
          actions.setFlashMessages(flashMessages)
        })
        .catch(handleAPIError(messages => actions.setFlashMessages({ error: messages })))
        .then(() => {
          actions.toggleInvitationsModal()
        })
    }
  })
})
