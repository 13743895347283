import { Ability } from 'app_search/classes'
import routes from 'app_search/routes'
import {
  IEngine,
  IObject,
  IRole,
  IUser,
  TRole
} from 'app_search/types'
import handleAPIError from 'app_search/utils/handleAPIError'
import { USERS_PATH } from 'app_search/utils/routePaths'
import http from 'shared/http'
import { storeLogic } from 'shared/store'
import { IStuiFlashMessagesProps } from 'stui/FlashMessages'
import { FlashMessagesLogic } from './FlashMessagesLogic'

export interface IUserData {
  availableEngines: IEngine[]
  demo: boolean
  hasAdvancedRoles: boolean
  myRole: IRole
  user: IUser
}

const defaultUserData: IUserData = {
  availableEngines: [],
  demo: false,
  hasAdvancedRoles: false,
  myRole: {} as IRole,
  user: {} as IUser
}

export interface IUserActions {
  clearFlashMessages()
  handleSaveUser(event: React.SyntheticEvent)
  handleRemoveUser(event: React.SyntheticEvent, history: IObject)
  initializeUserData(roleId: string)
  onHandleSaveUser(userData: IObject)
  selectFullEngineAccess()
  selectLimitedEngineAccess(engineNames: Set<string>)
  selectRoleType(selectedRoleType: TRole)
  setFlashMessages(flashMessages: IStuiFlashMessagesProps)
  setInitialUXState(state: IObject)
  setQueuedMessages(queuedMessages: IStuiFlashMessagesProps)
  setSelectedEngineNames(selectedEngineNames: Set<string>)
  setUserData(userData: IObject)
  setInitialState(userData: IObject, initialEngineNames: Set<string>, selectedAccessAllEngines: boolean, selectedEngineNames: Set<string>, selectedRoleType: TRole)
}

export interface IUserValues {
  dataLoading: boolean
  flashMessages?: IStuiFlashMessagesProps
  engineAccessEnabled: boolean
  initialEngineNames: Set<string>
  selectedAccessAllEngines: boolean
  selectedEngineNames: Set<string>
  selectedRoleType: string
  userData: IUserData
}

export const UserLogic = storeLogic({
  connect: () => ({
    actions: [
      FlashMessagesLogic, ['setFlashMessages', 'setQueuedMessages']
    ]
  }),
  actions: () => ({
    selectFullEngineAccess: true,
    selectLimitedEngineAccess: (engineNames: Set<string>) => ({ engineNames }),
    selectRoleType: (selectedRoleType: TRole) => ({ selectedRoleType }),
    setInitialState: (userData: IObject, initialEngineNames: Set<string>, selectedAccessAllEngines: boolean, selectedEngineNames: Set<string>, selectedRoleType: TRole) => ({ userData, initialEngineNames, selectedAccessAllEngines, selectedEngineNames, selectedRoleType }),
    setUserData: (userData: IObject) => userData,
    setSelectedEngineNames: (selectedEngineNames: Set<string>) => ({ selectedEngineNames })
  }),
  reducers: ({ actions }) => ({
    dataLoading: [true, {
      [actions.setInitialState]: () => false
    }],
    initialEngineNames: [new Set(), {
      [actions.setInitialState]: (_, { initialEngineNames }) => initialEngineNames
    }],
    selectedAccessAllEngines: [false, {
      [actions.setInitialState]: (_, { selectedAccessAllEngines }) => selectedAccessAllEngines,
      [actions.selectRoleType]: (currentSelectedAccessAllEngines, { selectedRoleType }) => Ability.canHaveScopedEngines(selectedRoleType) ? currentSelectedAccessAllEngines : true,
      [actions.selectFullEngineAccess]: () => true,
      [actions.selectLimitedEngineAccess]: () => false
    }],
    selectedEngineNames: [new Set(), {
      [actions.setInitialState]: (_, { selectedEngineNames }) => selectedEngineNames,
      [actions.selectRoleType]: (currentSelectedEngineNames, { selectedRoleType }) => Ability.canHaveScopedEngines(selectedRoleType) ? currentSelectedEngineNames : new Set(),
      [actions.setSelectedEngineNames]: (_, { selectedEngineNames }) => selectedEngineNames,
      [actions.selectFullEngineAccess]: () => new Set(),
      [actions.selectLimitedEngineAccess]: (_, { engineNames }) => engineNames
    }],
    selectedRoleType: ['', {
      [actions.setInitialState]: (_, { selectedRoleType }) => selectedRoleType,
      [actions.selectRoleType]: (_, { selectedRoleType }) => selectedRoleType
    }],
    userData: [defaultUserData, {
      [actions.setInitialState]: (oldUserData, { userData }) => ({ ...oldUserData, ...userData }),
      [actions.setUserData]: (oldUserData, userData) => ({ ...oldUserData, ...userData })
    }]
  }),
  selectors: ({ selectors }) => ({
    engineAccessEnabled: [
      () => [selectors.selectedRoleType],
      (selectedRoleType: TRole) => Ability.canHaveScopedEngines(selectedRoleType)
    ]
  }),
  thunks: (
    {
      actions,
      values
    }: { actions: IUserActions, values: IUserValues }
  ) => ({
    handleRemoveUser: (event: React.SyntheticEvent, history: IObject) => {
      const {
        userData
      } = values

      event.preventDefault()
      if (userData.demo) { return }

      const role = userData.user.role

      http({
        method: 'delete',
        url: routes.locoMocoRolePath(role.id)
      })
        .then(() => {
          if (userData.myRole.id === role.id) {
            // In this case the user is removing themselves from the account
            window.location.href = routes.sessionsSelectProductPath()
            return
          }

          actions.setQueuedMessages({ success: ['Successfully removed the user.'] })
          history.push(USERS_PATH)
        })
        .catch(handleAPIError(messages => actions.setFlashMessages({ error: messages })))
    },
    handleSaveUser: (event: React.SyntheticEvent) => {
      const {
        selectedAccessAllEngines,
        selectedEngineNames,
        selectedRoleType,
        userData
      } = values

      event.preventDefault()
      if (userData.demo) { return }

      const role = userData.user.role

      http({
        method: 'put',
        url: routes.locoMocoRolePath(role.id, {
          role_id: role.id,
          data: {
            role_type: selectedRoleType,
            access_all_engines: selectedAccessAllEngines,
            engine_names: Array.from(selectedEngineNames)
          }
        })
      })
        .then(({ data }) => {
          actions.setUserData(data)
          actions.setFlashMessages({ success: ['Changes saved successfully.'] })
        })
        .catch(handleAPIError(messages => actions.setFlashMessages({ error: messages })))
    },
    initializeUserData: (roleId: string) => {
      http(routes.locoMocoRolePath(roleId))
        .then(({ data }) => {
          const engineNamesSet = new Set<string>(data.user.engines.map((engine) => engine.name))
          actions.setInitialState(data, engineNamesSet, data.user.accessAllEngines, engineNamesSet, data.user.role.roleType)
          // actions.clearFlashMessages()
        })
        .catch(handleAPIError(messages => actions.setFlashMessages({ error: messages })))
    }
  })
})
