import React from 'react'

import { withRouter } from 'react-router-dom'
import { Elements, StripeProvider } from 'react-stripe-elements'

import AddOrUpdatePaymentMethodPanel from './AddOrUpdatePaymentMethodPanel'
import BillingInformationPanel from './BillingInformationPanel'
import BillingUsagePanel from './BillingUsagePanel'
import InvoicesTable from './InvoicesTable'
import ManageCurrentPlanPanel from './ManageCurrentPlanPanel'

import { Loading } from 'app_search/components/Loading'
import { PaymentVerificationRequiredModal } from 'app_search/components/PaymentVerificationRequiredModal'
import { PlanSelector } from 'app_search/components/PlanSelector'
import { PlanSelectorModal } from 'app_search/components/PlanSelectorModal'
import { ProcessingPaymentModal } from 'app_search/components/ProcessingPaymentModal'
import { UpdatePaymentMethodModal } from 'app_search/components/UpdatePaymentMethodModal'
import { BillingLogic } from 'app_search/logic/BillingLogic'
import { withStripe } from 'app_search/logic/withStripe'
import { IAccount, IObject, ISupportedLanguages } from 'app_search/types'

import {
  EuiFlexGroup,
  EuiSpacer,
  EuiTitle
} from '@elastic/eui'

import Header from 'app_search/Layout/Header'
import StuiFlashMessages, { IStuiFlashMessagesProps } from 'stui/FlashMessages'
import StuiHeading from 'stui/Heading'
import StuiMain from 'stui/Main'
import StuiSubHeading from 'stui/SubHeading'

interface IBillingProps {
  accountBillingMeta: IObject
  accountBillingUsage: IObject
  actions: {
    initializeBillingData()
    openUpdatePaymentMethodModalWithPlan()
    savePaymentMethod(stripeToken: string, onSuccess?: () => any, onError?: () => any, selectedPlan?: string)
    saveSelectedPlan(planName: string, onSuccess?: () => any, onError?: () => any)
    togglePlanSelectorModal()
    toggleUpdatePaymentMethodModal()
    closeAllModals()
    verifyPayment()
    cancelPaymentVerification()
  }
  canManagePlan: boolean
  dataLoading: boolean
  invoices: IObject[]
  lmAccount: IAccount
  flashMessages: IStuiFlashMessagesProps
  stripe: any | null
  uiState: IObject
  selectedPlan: IObject | null
  paymentIntentClientSecret: string | null
  company: string
  domain: string
  selectedBusiness: string
  selectedEmployeesNumber: string
  businessOptions: ISupportedLanguages[]
  employeesNumberOptions: ISupportedLanguages[]
  countryOptions: ISupportedLanguages[]
  address1: string
  address2: string
  city: string
  state: string
  postal_code: string
  country: string
}

class Billing extends React.Component<IBillingProps> {
  componentDidMount() {
    const { actions: { initializeBillingData } } = this.props
    initializeBillingData()
  }

  render() {
    const {
      actions: {
        openUpdatePaymentMethodModalWithPlan,
        savePaymentMethod,
        saveSelectedPlan,
        closeAllModals,
        verifyPayment,
        cancelPaymentVerification,
        togglePlanSelectorModal,
        toggleUpdatePaymentMethodModal
      },
      accountBillingMeta,
      accountBillingUsage,
      canManagePlan,
      dataLoading,
      flashMessages,
      invoices,
      selectedPlan,
      stripe,
      uiState,
      company,
      domain,
      selectedBusiness,
      selectedEmployeesNumber,
      businessOptions,
      employeesNumberOptions,
      countryOptions,
      address1,
      address2,
      city,
      state,
      postal_code,
      country
    } = this.props

    const {
      creditCard,
      hasCard,
      isFreeTrial,
      plan
    } = accountBillingMeta

    if (dataLoading || stripe === null) { return <Loading /> }

    return (
      <div data-test-subj="Billing">
        <Header viewHeader={true}>
          <EuiTitle size="l">
            <h1>Billing</h1>
          </EuiTitle>
          <StuiSubHeading>{canManagePlan ? 'Manage' : 'View'} your plan and payment method</StuiSubHeading>
        </Header>
        <StuiMain>
          <StuiFlashMessages {...flashMessages} />
          {isFreeTrial ? (
            <>
              <EuiSpacer />
              <Header>
                <StuiHeading type="section">Select a Plan</StuiHeading>
              </Header>
              <EuiSpacer />
              <PlanSelector
                accountBillingMeta={accountBillingMeta}
                canManagePlan={canManagePlan}
                onPlanSelect={s => saveSelectedPlan(s, closeAllModals, closeAllModals)}
                openUpdatePaymentMethodModalWithPlan={openUpdatePaymentMethodModalWithPlan}
              />
              {hasCard && (
                <>
                  <EuiSpacer size="xxl" />
                  <div className="billing-information__card ostui-panel">
                    <AddOrUpdatePaymentMethodPanel
                      canManagePlan={canManagePlan}
                      creditCard={creditCard}
                      onUpdatePaymentMethod={toggleUpdatePaymentMethodModal}
                    />
                  </div>
                </>
              )}
            </>
          ) : (
              <>
                <EuiFlexGroup gutterSize="l">
                  <BillingUsagePanel
                    accountBillingMeta={accountBillingMeta}
                    accountBillingUsage={accountBillingUsage}
                  />
                  <BillingInformationPanel
                    accountBillingMeta={accountBillingMeta}
                    accountBillingUsage={accountBillingUsage}
                  />
                </EuiFlexGroup>

                <EuiSpacer />

                <EuiFlexGroup gutterSize="l">
                  <ManageCurrentPlanPanel
                    canManagePlan={canManagePlan}
                    onUpdatePlan={togglePlanSelectorModal}
                    plan={plan}
                  />
                  <AddOrUpdatePaymentMethodPanel
                    canManagePlan={canManagePlan}
                    creditCard={creditCard}
                    onUpdatePaymentMethod={toggleUpdatePaymentMethodModal}
                  />
                </EuiFlexGroup>

                <EuiSpacer size="xxl" />

                <InvoicesTable invoices={invoices} />
              </>
            )}
        </StuiMain>

        {uiState.planSelectorModalOpen &&
          <PlanSelectorModal
            accountBillingMeta={accountBillingMeta}
            canManagePlan={canManagePlan}
            closePlanSelectorModal={togglePlanSelectorModal}
            onPlanSelect={s => saveSelectedPlan(s, closeAllModals, closeAllModals)}
            openUpdatePaymentMethodModalWithPlan={openUpdatePaymentMethodModalWithPlan}
          />
        }

        {uiState.updatePaymentMethodModalOpen &&
          <StripeProvider stripe={stripe}>
            <Elements>
              <UpdatePaymentMethodModal
                accountBillingMeta={accountBillingMeta}
                closeUpdatePaymentMethodModal={toggleUpdatePaymentMethodModal}
                selectedPlan={selectedPlan}
                countryOptions={countryOptions}
                address1={address1}
                address2={address2}
                city={city}
                state={state}
                postal_code={postal_code}
                country={country}
                company={company}
                domain={domain}
                selectedBusiness={selectedBusiness}
                selectedEmployeesNumber={selectedEmployeesNumber}
                businessOptions={businessOptions}
                employeesNumberOptions={employeesNumberOptions}
                onSavePaymentMethod={stripeToken => savePaymentMethod(stripeToken, closeAllModals, closeAllModals, selectedPlan && selectedPlan.name)}
              />
            </Elements>
          </StripeProvider>
        }

        {uiState.processingPaymentModalOpen &&
          <ProcessingPaymentModal />
        }

        {uiState.paymentVerificationRequiredModalOpen &&
          <PaymentVerificationRequiredModal selectedPlan={selectedPlan} cancelPaymentVerification={cancelPaymentVerification} verifyPayment={verifyPayment} />
        }
      </div>
    )
  }
}

export default withStripe(withRouter(BillingLogic(Billing)))
