import React, { useEffect } from 'react'

import { Link, withRouter } from 'react-router-dom'

import { ENGINE_DOCUMENTS_PATH, getEngineRoute } from 'app_search/utils/routePaths'

import { Loading } from 'app_search/components/Loading'
import { IEngineDetails, IMatch, IObject, IRole } from 'app_search/types'
import useAbilities from 'app_search/utils/useAbilities'

import { DocumentsLogic } from './DocumentsLogic'

import { EuiButton } from '@elastic/eui'

import Header from 'app_search/Layout/Header'
import StuiFlashMessages, { IStuiFlashMessagesProps } from 'stui/FlashMessages'
import StuiHeading from 'stui/Heading'
import StuiMain from 'stui/Main'
import { StuiResultFieldValue } from 'stui/Result'
import {
  StuiTable,
  StuiTableBody,
  StuiTableBodyCell,
  StuiTableHead,
  StuiTableHeadCell,
  StuiTableRow
} from 'stui/Table'

export interface IDocumentDetailProps {
  actions: IObject
  preLoadedSchema: IObject
  preLoadedEngine: IEngineDetails
  role: IRole
  dataLoading: boolean
  engineName: string
  history: any
  match: IMatch
  engine: IEngineDetails
  fields: IObject[]
  flashMessages: IStuiFlashMessagesProps
}

const DocumentDetail: React.SFC<IDocumentDetailProps> = ({
  actions: {
    deleteDocument,
    getDocumentDetails,
    setFlashMessages,
    setFields
  },
  match: { params: { documentId } },
  dataLoading,
  engineName,
  flashMessages,
  fields,
  history
}) => {
  const { canManageEngineDocuments } = useAbilities()

  useEffect(() => {
    getDocumentDetails(documentId)
    return () => {
      setFlashMessages(null)
      setFields([])
    }
  }, [])

  if (dataLoading) { return <Loading /> }

  const hasfields = fields.length > 0
  const deleteDocumentButton = (
    <EuiButton
      color="danger"
      iconType="trash"
      onClick={e => deleteDocument(e, documentId, history)}
      data-test-subj="DeleteDocumentButton"
    >
      Delete
    </EuiButton>
  )

  return (
    <div data-test-subj="DocumentDetail">
      <div className="stui-view-topbar">
        <div className="o-stui-breadcrumbs">
          <Link to={getEngineRoute(ENGINE_DOCUMENTS_PATH, engineName)} className="o-stui-breadcrumb">Documents</Link>
          <div className="o-stui-breadcrumb">{decodeURIComponent(documentId)}</div>
        </div>
      </div>
      <Header viewHeader={true} contained={true} actions={hasfields && canManageEngineDocuments ? deleteDocumentButton : null}>
        <StuiHeading type="view">
          Document: {decodeURIComponent(documentId)}
        </StuiHeading>
      </Header>
      <StuiMain>
        <StuiFlashMessages {...flashMessages} />
        <StuiTable className="document-table">
          {hasfields &&
            <StuiTableHead>
              <StuiTableRow>
                <StuiTableHeadCell>Field</StuiTableHeadCell>
                <StuiTableHeadCell>Value</StuiTableHeadCell>
              </StuiTableRow>
            </StuiTableHead>}
          <StuiTableBody>
            {fields.map(({ name, value, type }) => (
              <StuiTableRow key={name}>
                <StuiTableBodyCell><strong>{name}</strong></StuiTableBodyCell>
                <StuiTableBodyCell className="document-table__value">
                  <StuiResultFieldValue raw={value} type={type} />
                </StuiTableBodyCell>
              </StuiTableRow>
            ))}
          </StuiTableBody>
        </StuiTable>
      </StuiMain>
    </div>
  )
}

export default withRouter(DocumentsLogic(DocumentDetail))
