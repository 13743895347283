import { storeLogic } from 'shared/store'
import { IStuiFlashMessagesProps } from 'stui/FlashMessages'

export interface IFlashMessagesActions {
  loadQueuedMessages()
  setFlashMessages(flashMessages: IStuiFlashMessagesProps)
  setQueuedMessages(queuedMessages: IStuiFlashMessagesProps)
}

export interface IFlashMessagesValues {
  flashMessages: IStuiFlashMessagesProps
  queuedMessages: IStuiFlashMessagesProps
}

export const FlashMessagesLogic = storeLogic({
  actions: () => ({
    setFlashMessages: (flashMessages: IStuiFlashMessagesProps) => ({ flashMessages }),
    setQueuedMessages: (queuedMessages: IStuiFlashMessagesProps) => ({ queuedMessages })
  }),
  reducers: ({ actions }) => ({
    flashMessages: [{}, {
      [actions.setFlashMessages]: (_, { flashMessages }) => flashMessages
    }],
    queuedMessages: [{}, {
      [actions.setFlashMessages]: () => ({}),
      [actions.setQueuedMessages]: (_, { queuedMessages }) => queuedMessages
    }]
  }),
  thunks: ({ values, actions }: { values: IFlashMessagesValues, actions: IFlashMessagesActions }) => ({
    loadQueuedMessages: () => {
      actions.setFlashMessages(values.queuedMessages)
    }
  })
})
