import React from 'react'

import classNames from 'classnames'

import { CurationsLogic } from './CurationsLogic'

import {
  EuiButtonEmpty,
  EuiSpacer,
  EuiSwitch
} from '@elastic/eui'

import StuiFlashMessages, { IStuiFlashMessagesProps } from 'stui/FlashMessages'

import { ICurationQueryItem } from 'app_search/types'

import CurationQuery from './CurationQuery'

interface ICurationQueriesProps {
  className: string
  actions: {
    toggleMultiQuery()
    addQueryItem()
  }
  curationQueries: ICurationQueryItem[]
  isNewCuration?: boolean
  isMultiQuery: boolean
  queryFormFlashMessages: IStuiFlashMessagesProps
  canEdit: boolean
}

class CurationQueries extends React.Component<ICurationQueriesProps> {
  render() {
    const {
      className,
      actions: {
        toggleMultiQuery,
        addQueryItem
      },
      isNewCuration,
      isMultiQuery,
      canEdit,
      curationQueries,
      queryFormFlashMessages
    } = this.props

    const queriesListClassNames = classNames('curation-queries', className)
    const hasEmptyFocusedInput = (): boolean => {
      let hasEmptyFocused = false
      curationQueries.forEach(query => {
        if (query.focusInput && !query.text) { hasEmptyFocused = true }
      })
      return hasEmptyFocused
    }

    return (
      <div>
        {isNewCuration &&
          <>
            <EuiSwitch
              label="Multiple queries"
              checked={isMultiQuery}
              onChange={toggleMultiQuery}
              data-test-subj="MultpleQueriesCurationCheckbox"
            />
            <EuiSpacer size="m" />
          </>
        }
        <StuiFlashMessages {...queryFormFlashMessages} />
        <div className="curation-queries-container">
          <div className={queriesListClassNames}>
            {curationQueries.map((query, i) => (
              <CurationQuery
                key={i}
                index={i}
                focused={query.focusInput}
                curationQuery={query}
                isActive={!isNewCuration && i === 0}
                canEdit={canEdit}
                canActivate={canEdit && !isNewCuration && (i > 0)}
                isNewCuration={isNewCuration}
                canDelete={canEdit && (curationQueries.length > 1)}
              />
            ))}
          </div>
          {(canEdit && (isMultiQuery || !isNewCuration)) &&
            <EuiButtonEmpty
              isDisabled={hasEmptyFocusedInput()}
              size="s"
              iconType="plusInCircle"
              className="curations-add-query"
              onClick={addQueryItem}
              data-test-subj="AddQueryCurationsButton"
            >
              Add Query
            </EuiButtonEmpty>}
        </div>
      </div>
    )
  }
}

export default CurationsLogic(CurationQueries)
