import React from 'react'

import {
  EuiButton,
  EuiModalBody,
  EuiModalFooter,
  EuiSpacer
} from '@elastic/eui'

import Header from 'app_search/Layout/Header'
import {
  StuiAccordion,
  StuiAccordionItem,
  StuiAccordionItemBody,
  StuiAccordionItemHeader
} from 'stui/Accordion'
import StuiCode from 'stui/Code'
import StuiHeading from 'stui/Heading'
import StuiIcon from 'stui/Icon'

import { IIndexSummary } from 'app_search/types'

function pluralizeDocuments(count) {
  return count === 1 ? 'document' : 'documents'
}

interface IDocumentCreationSummaryProps {
  buttonTitle: string
  description: string
  indexSummary: IIndexSummary
  buttonClick()
}

const DocumentCreationSummary: React.SFC<IDocumentCreationSummaryProps> = ({
  description,
  buttonTitle,
  indexSummary: {
    newSchemaFields,
    validDocuments,
    invalidDocuments
  },
  buttonClick
}) => (
    <>
      <EuiModalBody>
        <Header>
          <StuiHeading type="section">Indexing Summary</StuiHeading>
          <p>{description}</p>
        </Header>
        <EuiSpacer />
        <StuiAccordion className="o-stui-styled-accordion">
          {invalidDocuments.total > 0 && <StuiAccordionItem className="onboarding-create-documents-summary onboarding-create-documents-summary--failed-documents">
            <StuiAccordionItemHeader>
              <StuiIcon name="error-fill" />&nbsp;
            <p>
                <strong>{invalidDocuments.total} {pluralizeDocuments(invalidDocuments.total)}</strong> with errors...
            </p>
            </StuiAccordionItemHeader>
            <StuiAccordionItemBody>
              {invalidDocuments.examples.map((example, index) => {
                const errors = <Header>
                  <StuiHeading type="title">This document was not indexed!</StuiHeading>
                  <EuiSpacer />
                  {example.errors.map((errorMessage, errorIndex) => <p key={errorIndex}>{errorMessage}</p>)}
                </Header>
                return <div key={index}>
                  <EuiSpacer size="s" />
                  <StuiCode language="json" error={errors} isParseable={true} isCollapsible={true} isScrollable={true}>
                    {JSON.stringify(example.document, null, 2)}
                  </StuiCode>
                </div>
              })}
              <EuiSpacer size="s" />
              {invalidDocuments.total - invalidDocuments.examples.length > 0 &&
                <p>and {invalidDocuments.total - invalidDocuments.examples.length} other documents.</p>}
              <EuiSpacer />
            </StuiAccordionItemBody>
          </StuiAccordionItem>}

          {validDocuments.total === 0 && <StuiAccordionItem className="onboarding-create-documents-summary onboarding-create-documents-summary--no-successful-documents">
            <StuiAccordionItemHeader>
              <StuiIcon name="info-fill" />&nbsp;
            <p>No new documents.</p>
            </StuiAccordionItemHeader>
          </StuiAccordionItem>}

          {validDocuments.total > 0 && <StuiAccordionItem className="onboarding-create-documents-summary onboarding-create-documents-summary--successful-documents">
            <StuiAccordionItemHeader>
              <StuiIcon name="success-fill" />&nbsp;
            <p>Added <strong>{validDocuments.total} {pluralizeDocuments(validDocuments.total)}</strong>.</p>
            </StuiAccordionItemHeader>
            <StuiAccordionItemBody>
              {validDocuments.examples.map((document, index) =>
                <div key={index}>
                  <EuiSpacer size="s" />
                  <StuiCode isParseable={true} isCollapsible={true}>
                    {JSON.stringify(document, null, 2)}
                  </StuiCode>
                </div>
              )}
              <EuiSpacer size="s" />
              {validDocuments.total - validDocuments.examples.length > 0 && <p>and {validDocuments.total - validDocuments.examples.length} other documents</p>}
              <EuiSpacer />
            </StuiAccordionItemBody>
          </StuiAccordionItem>}

          <StuiAccordionItem className="onboarding-create-documents-summary onboarding-create-documents-summary--schema-changes">
            <StuiAccordionItemHeader>
              <StuiIcon name="info-fill" />&nbsp;
            {newSchemaFields.length > 0 && <p>
                Added <strong>{newSchemaFields.length} {newSchemaFields.length === 1 ? 'field' : 'fields'}</strong> to the Engine's schema.
            </p>}
              {newSchemaFields.length === 0 && <p>No new schema fields.</p>}
            </StuiAccordionItemHeader>
            {newSchemaFields.length > 0 && <StuiAccordionItemBody>
              <EuiSpacer size="s" />
              <ul className="onboarding-create-documents-summary__change-list">
                {newSchemaFields.map((schemaField) => <li className="o-stui-code-snippet" key={schemaField}>{schemaField}</li>)}
              </ul>
              <EuiSpacer size="s" />
            </StuiAccordionItemBody>}
          </StuiAccordionItem>
        </StuiAccordion>
      </EuiModalBody>
      <EuiModalFooter>
        <EuiButton
          data-test-subj={`DocumentImport${buttonTitle.replace(/\s/g, '')}Button`}
          onClick={buttonClick}
        >
          {buttonTitle}
        </EuiButton>
      </EuiModalFooter>
    </>
  )

export default DocumentCreationSummary
