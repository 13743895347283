import React, { useEffect } from 'react'

import { withRouter } from 'react-router-dom'
import { AccountSettingsLogic } from './AccountSettingsLogic'

import { Loading } from 'app_search/components/Loading'
import { IAccountProps, IObject, IRole, IZxcvbnResult } from 'app_search/types'
import { getPasswordStrengthMessage, newPasswordIsNotStrongEnough } from 'app_search/utils'
import useAbilities from 'app_search/utils/useAbilities'

import {
  EuiButton,
  EuiCallOut,
  EuiFieldPassword,
  EuiFieldText,
  EuiForm,
  EuiFormRow,
  EuiLink,
  EuiPanel,
  EuiRadioGroup,
  EuiSpacer,
  EuiSwitch,
  EuiText,
  EuiTitle
} from '@elastic/eui'

import Header from 'app_search/Layout/Header'
import StuiFlashMessages, { IStuiFlashMessagesProps } from 'stui/FlashMessages'
import StuiHeading from 'stui/Heading'
import StuiMain from 'stui/Main'
import StuiPasswordStrength from 'stui/PasswordStrength'

declare const window: {
  zxcvbn?: any
  zxcvbnUserInputsFromEmail?: any
} & Window

interface ICancellationReasons {
  id: string
  reason: string
}

interface IAccountSettingsProps {
  dataLoading: boolean
  actions: {
    closeAccount(role: IRole, googleOnlyUser: boolean)
    initializeUserInfo()
    setFormValue(key: string, value: string)
    setUserInfoValue(key: string, value: string)
    toggleTelemetryOptIn(value: boolean)
    updateUser()
    updateAccount()
    resetAccountSettings()
  }
  userInfo: {
    accountDisabled?: boolean
    accountName?: string
    role: IRole
    canChangePassword: boolean
    email?: string
    unconfirmedEmail?: string
    pendingReconfirmation: boolean
    googleOnlyUser: boolean
    cancellationReasons: ICancellationReasons[]
  }
  account: IAccountProps
  telemetryOptedInUpdating?: boolean
  minPasswordScore?: number
  formData: IObject
  flashMessages: IStuiFlashMessagesProps
}

const AccountSettings: React.SFC<IAccountSettingsProps> = ({
  dataLoading,
  actions: {
    closeAccount,
    initializeUserInfo,
    setFormValue,
    setUserInfoValue,
    toggleTelemetryOptIn,
    updateUser,
    updateAccount,
    resetAccountSettings
  },
  userInfo: {
    accountDisabled,
    accountName,
    role,
    canChangePassword,
    email,
    unconfirmedEmail,
    pendingReconfirmation,
    googleOnlyUser,
    cancellationReasons
  },
  account: {
    telemetryOptedIn
  },
  telemetryOptedInUpdating,
  minPasswordScore,
  formData,
  flashMessages
}) => {
  const { canDestroySession, canManageSettings, canManageTelemetryData } = useAbilities()

  useEffect(() => {
    initializeUserInfo()
    return resetAccountSettings
  }, [])

  const handleUpdateAccount = event => {
    event.preventDefault()
    updateAccount()
  }

  const handleUpdateUser = event => {
    event.preventDefault()
    updateUser()
  }

  const handleCloseAccount = event => {
    event.preventDefault()
    closeAccount(role, googleOnlyUser)
  }

  if (dataLoading) { return <Loading /> }

  const { currentPassword, newPassword, confirmPassword } = formData

  const pendingReconfirmationContent = pendingReconfirmation && (
    <EuiCallOut
      color="warning"
      iconType="alert"
      title="Your email address is pending confirmation"
    >
      <EuiText size="s">
        <p>Head to your email inbox at <strong>{unconfirmedEmail}</strong> and verify your email address by clicking on the provided link.
          If you haven't received a verification email from Swiftype, please check your spam folder.</p>
      </EuiText>
    </EuiCallOut>
  )

  const accountSettingsForm = !accountDisabled && canManageSettings && (
    <>
      <EuiTitle size="s">
        <h3>Account Settings</h3>
      </EuiTitle>
      <EuiText>
        <p>Manage your global account settings. An account name will help team members identify the accounts they may access.</p>
      </EuiText>
      <EuiSpacer />

      <EuiForm>
        <form onSubmit={handleUpdateAccount}>
          <EuiFormRow label="Account Name">
            <EuiFieldText
              value={accountName || ''}
              data-test-subj="SettingsAccountNameField"
              onChange={e => setUserInfoValue('accountName', e.target.value)}
            />
          </EuiFormRow>
          <EuiButton onClick={handleUpdateAccount}>Update account settings</EuiButton>
        </form>
      </EuiForm>
      <EuiSpacer size="xxl" />
    </>
  )

  let zxcvbnResult: IZxcvbnResult | null
  if (window.zxcvbn && newPassword !== '') {
    const userInputs = email != null ? window.zxcvbnUserInputsFromEmail(email) : []
    zxcvbnResult = window.zxcvbn(newPassword, userInputs)
  } else {
    zxcvbnResult = null
  }

  const changePasswordForm = canChangePassword && (
    <>
      <EuiTitle size="s">
        <h3>User Settings</h3>
      </EuiTitle>
      <EuiText>
        <p>Change your email address and password</p>
      </EuiText>
      <EuiSpacer />

      <EuiForm>
        <form onSubmit={handleUpdateUser}>
          <EuiFormRow label="Email Address">
            <EuiFieldText
              value={email}
              autoComplete="off"
              data-test-subj="SettingsEmailField"
              onChange={e => setUserInfoValue('email', e.target.value)}
            />
          </EuiFormRow>
          <EuiFormRow
            label="Current Password"
            helpText="Your current password is required to update user settings."
          >
            <EuiFieldPassword
              value={currentPassword}
              autoComplete="off"
              data-test-subj="SettingsCurrentPasswordField"
              onChange={e => setFormValue('currentPassword', e.target.value)}
              required={true}
            />
          </EuiFormRow>
          <EuiFormRow
            label="New Password"
            helpText={getPasswordStrengthMessage(zxcvbnResult, minPasswordScore)}
          >
            <>
              <EuiFieldPassword
                value={newPassword}
                autoComplete="off"
                data-test-subj="SettingsNewPasswordField"
                onChange={e => setFormValue('newPassword', e.target.value)}
              />
              {(!process.env.LOCO_TOGO && newPassword !== '' && zxcvbnResult !== null) &&
                <StuiPasswordStrength strengthScore={zxcvbnResult.score || 0} />
              }
            </>
          </EuiFormRow>
          <EuiFormRow label="Confirm New Password">
            <EuiFieldPassword
              value={confirmPassword}
              autoComplete="off"
              data-test-subj="SettingsConfirmPasswordField"
              onChange={e => setFormValue('confirmPassword', e.target.value)}
            />
          </EuiFormRow>
          <EuiButton
            type="submit"
            data-test-subj="SettingsUpdateUserButton"
            disabled={currentPassword.length === 0 || newPasswordIsNotStrongEnough(zxcvbnResult, minPasswordScore)}
          >
            Update user settings
          </EuiButton>
        </form>
      </EuiForm>
      <EuiSpacer size="xxl" />
    </>
  )

  const telemetryPanel = process.env.LOCO_TOGO && canManageTelemetryData && (
    <>
      <EuiTitle size="s">
        <h3>Usage Data</h3>
      </EuiTitle>
      <EuiText>
        <p>
          Help us improve App Search by providing usage statistics.
            <br />
          We will not share this data outside of Elastic.
          </p>
      </EuiText>
      <EuiSpacer size="m" />
      <EuiText>
        <EuiSwitch
          label={telemetryOptedIn ? 'On' : 'Off'}
          checked={telemetryOptedIn}
          onChange={e => toggleTelemetryOptIn(e.target.checked)}
          disabled={telemetryOptedInUpdating}
          data-test-subj="TelemetryDataSettingsSwitch"
        />
      </EuiText>
      <EuiSpacer size="m" />
      <EuiText>
        <p>
          {/* In unsecured SMAS the user cannot log out and have other users so mentioning 'owners' could seem confusing. */}
          {canDestroySession && <>
            This is an account level setting which is only configurable by owners.
            <br />
          </>}
          <EuiLink href="https://www.elastic.co/legal/telemetry-privacy-statement" target="_blank">Read our telemetry privacy statement</EuiLink>
        </p>
      </EuiText>
      <EuiSpacer size="xxl" />
    </>
  )

  const closeAccountPanel = !process.env.LOCO_TOGO && canManageSettings && (
    <EuiPanel paddingSize="l" hasShadow={true} data-test-subj="AccountSettingsCloseAccountPanel">
      <EuiTitle size="s">
        <h3>Close Account</h3>
      </EuiTitle>
      <EuiText>
        <p>Please leave your feedback and help us build a better product!</p>
      </EuiText>
      <EuiSpacer />

      <EuiForm>
        <form>
          <EuiFormRow label="Optionally, tell us what made you leave:">
            <>
              <EuiRadioGroup
                options={cancellationReasons}
                idSelected={formData.reason}
                onChange={reason => setFormValue('reason', reason)}
              />
            </>
          </EuiFormRow>
          <EuiSpacer />
          <EuiCallOut
            color="danger"
            title="Please note that deleting your account is permanent and cannot be undone"
          >
            <EuiText size="s">
              {googleOnlyUser ?
                <p>Authenticate with Google to confirm your identity and close your account.</p>
                :
                <p>Enter your password below to confirm your identity and close your account.</p>
              }
            </EuiText>
            <EuiSpacer size="s" />
            {!googleOnlyUser &&
              <EuiFormRow label="Confirm Password">
                <EuiFieldPassword
                  data-test-subj="SettingsCloseAccountPasswordField"
                  onChange={e => setFormValue('closeAccountPassword', e.target.value)}
                />
              </EuiFormRow>
            }
            <EuiButton
              color="danger"
              fill={true}
              data-test-subj="SettingsCloseAccountButton"
              data-confirm={
                'Are you sure you want to permanently close your account? ' +
                'Closing your account will permanently destroy all of your engines and documents. ' +
                'This action is not reversible.'
              }
              onClick={handleCloseAccount}
            >
              Close account
            </EuiButton>
          </EuiCallOut>
        </form>
      </EuiForm>
    </EuiPanel>
  )

  return (
    <div data-test-subj="AccountSettings">
      <Header key="header" contained={true} viewHeader={true}>
        <StuiHeading type="view">Settings</StuiHeading>
      </Header>
      <StuiMain key="main">
        {pendingReconfirmationContent}
        <EuiSpacer />
        <StuiFlashMessages {...flashMessages} />
        {accountSettingsForm}
        {changePasswordForm}
        {telemetryPanel}
        {closeAccountPanel}
      </StuiMain>
    </div>
  )
}

export default withRouter(AccountSettingsLogic(AccountSettings))
