import React, { useEffect, ReactNode } from 'react'

import {
  EuiBasicTable,
  EuiButton,
  EuiFlexGroup,
  EuiFlexItem,
  EuiHealth,
  EuiIcon,
  EuiText
} from '@elastic/eui'
import {
  useActions,
  useValues
} from 'kea'
import _flatten from 'lodash/flatten'
import _slice from 'lodash/slice'
import { Link, withRouter } from 'react-router-dom'

import { Loading } from 'app_search/components/Loading'
import Header from 'app_search/Layout/Header'
import {
  IEngineDetails,
  IObject,
  IMetaPage
} from 'app_search/types'
import {
  META_ENGINE_NEW_PATH,
  getMetaEngineRoute
} from 'app_search/utils/routePaths'
import useAbilities from 'app_search/utils/useAbilities'
import StuiEmptyState from 'stui/EmptyState'
import StuiFlashMessages, { IStuiFlashMessagesProps } from 'stui/FlashMessages'
import StuiHeading from 'stui/Heading'
import StuiMain from 'stui/Main'
import StuiSubHeading from 'stui/SubHeading'

import {
  IConflictingEnginesSets,
  MetaEnginesLogic
} from './MetaEnginesLogic'

interface IItemIdToExpandedRowMap {
  [id: string]: ReactNode
}

interface IMetaEnginesProps {
  history: IObject
}

interface IMetaEnginesActions {
  deleteMetaEngine(name: string)
  fetchMetaEngines(history: IObject, page?: number, size?: number)
  fetchOrDisplayRow(itemId: string)
  hideRow(itemId: string)
  initializeMetaEnginesData(history: IObject)
  resetMetaEnginesData()
}

interface IMetaEnginesValues {
  conflictingEnginesSets: IConflictingEnginesSets
  dataLoading: boolean
  expandedSourceEngines: object
  flashMessages: IStuiFlashMessagesProps
  metaEngines: IEngineDetails[]
  pagination: IMetaPage
}

const MetaEngines: React.SFC<IMetaEnginesProps> = ({
  history
}) => {
  const { canManageMetaEngines } = useAbilities()
  const {
    deleteMetaEngine,
    fetchMetaEngines,
    fetchOrDisplayRow,
    hideRow,
    initializeMetaEnginesData
  } = useActions(MetaEnginesLogic) as IMetaEnginesActions

  const {
    conflictingEnginesSets,
    dataLoading,
    expandedSourceEngines,
    flashMessages,
    metaEngines,
    pagination
  } = useValues(MetaEnginesLogic) as IMetaEnginesValues

  useEffect(() => {
    initializeMetaEnginesData(history)
  }, [])

  if (dataLoading) { return (<Loading />) }

  const CreateAMetaEngineButton = () => (
    <EuiButton
      color="secondary"
      data-test-subj="MetaEnginesListCreateButton"
      fill={true}
    >
      <Link to={META_ENGINE_NEW_PATH}>Create a Meta Engine</Link>
    </EuiButton>
  )

  const EmptyStateCTAButtons = () => (
    <EuiFlexGroup component="span">
      <EuiFlexItem grow={false}>
        <EuiButton
          href="https://swiftype.com/documentation/app-search/guides/meta-engines"
          target="_blank"
          color="primary"
          data-test-subj="MetaEnginesDocumentationButton"
          fill={true}
        >
          Read Meta Engine Documentation
        </EuiButton>
      </EuiFlexItem>
      {canManageMetaEngines &&
        <EuiFlexItem grow={false}>
          <CreateAMetaEngineButton />
        </EuiFlexItem>
      }
    </EuiFlexGroup>
  )

  const HeaderDescription = () => (
    <StuiSubHeading>
      <EuiText>Meta Engines allow you to combine multiple Engines into one, searchable Engine.</EuiText>
      <EuiText>
        <a
          href="https://swiftype.com/documentation/app-search/guides/meta-engines"
          target="_blank"
        >
          Read the Documentation
        </a> for information about how to get started.</EuiText>
    </StuiSubHeading>
  )

  const MetaEngineSources = ({ sourceEngines, conflictingEngines }: { sourceEngines: IEngineDetails[], conflictingEngines: Set<string> }) => (
    <div
      className="meta-engines__source-engines-table"
    >
      {
        sourceEngines.map(({ name, field_count, document_count }) =>
          <div
            className="meta-engines__source-engines-table-row"
            key={name}
          >
            <div>{name}</div>
            <div>{document_count}</div>
            <div>{conflictingEngines.has(name) ? <EuiHealth color="warning">{field_count}</EuiHealth> : field_count}</div>
          </div>
        )}
    </div>
  )

  const itemIdToExpandedRowMap: IItemIdToExpandedRowMap = Object.keys(expandedSourceEngines).reduce((accumulator, engineName) => {
    return {
      ...accumulator,
      [engineName]: <MetaEngineSources sourceEngines={expandedSourceEngines[engineName]} conflictingEngines={conflictingEnginesSets[engineName]} />
    }
  }, {})

  const metaEnginesTableActions = canManageMetaEngines ? [
    {
      name: 'Delete',
      description: 'Delete this Meta Engine',
      type: 'icon',
      icon: 'trash',
      onClick: (item) => {
        deleteMetaEngine(item.name)
      }
    }
  ] : []

  const MetaEnginesTableNameContent = ({ name, item }: { name: string, item: IEngineDetails }) => (
    <EuiFlexGroup
      direction="column"
      gutterSize="none"
    >
      <Link
        to={getMetaEngineRoute(name)}
      >
        <strong>{name}</strong>
      </Link>
      <EuiFlexGroup
        alignItems="center"
        gutterSize="s"
        onClick={() => itemIdToExpandedRowMap[name] ? hideRow(name) : fetchOrDisplayRow(name)}
        className="meta-engines__expand-row"
      >
        <EuiFlexItem grow={false} ><EuiIcon type={itemIdToExpandedRowMap[name] ? 'arrowDown' : 'arrowRight'} /></EuiFlexItem>
        <EuiFlexItem grow={false}>{item.engine_count || '0'} Engine{item.engine_count !== 1 ? 's' : ''}</EuiFlexItem>
        {item.schemaConflicts && Object.keys(item.schemaConflicts).length > 0 &&
          <EuiFlexItem grow={false}>
            <EuiHealth color="warning">Field-type conflict</EuiHealth>
          </EuiFlexItem>
        }
      </EuiFlexGroup>
    </EuiFlexGroup>
  )

  const hasMetaEngines: boolean = metaEngines.length > 0

  return (
    <div
      className="meta-engines"
      data-test-subj="MetaEngines"
    >
      <Header contained={true} viewHeader={true} actions={canManageMetaEngines && <CreateAMetaEngineButton />}>
        <StuiHeading type="view">Meta Engines</StuiHeading>
        {hasMetaEngines && <HeaderDescription />}
      </Header>
      <StuiMain>
        <StuiFlashMessages {...flashMessages} />
        {hasMetaEngines
          ? (
            <EuiBasicTable
              className="meta-engines__table"
              items={metaEngines}
              responsive={false}
              columns={[
                {
                  field: 'name',
                  name: 'Name',
                  render: (name: string, item: IEngineDetails) => <MetaEnginesTableNameContent name={name} item={item} />
                },
                {
                  field: 'document_count',
                  name: 'Documents',
                  render: (documentCount) => <strong>{documentCount.toLocaleString()}</strong>,
                  width: '20%'
                },
                {
                  field: 'field_count',
                  name: 'Fields',
                  render: (fieldCount) => <strong>{fieldCount.toLocaleString()}</strong>,
                  width: '20%'
                },
                {
                  name: '',
                  actions: metaEnginesTableActions,
                  width: '40px'
                }
              ]}
              pagination={{ ...pagination, hidePerPageOptions: true }}
              itemId="name"
              itemIdToExpandedRowMap={itemIdToExpandedRowMap}
              onChange={({ page: { index, size } }) => {
                fetchMetaEngines(history, index, size)
              }}
            />
          ) : (
            <StuiEmptyState
              title="No meta-engines yet"
              description="Meta Engines combine the documents from your existing engines to enable search over multiple engines at once."
              action={<EmptyStateCTAButtons />}
            />
          )
        }
      </StuiMain>
    </div>
  )
}

export default withRouter(MetaEngines)
