import React from 'react'

import {
  EuiIcon
} from '@elastic/eui'

const ReadOnlyModeBanner: React.SFC<{}> = () => {
  return (
    <>
      <div className="read-only-mode-banner" data-test-subj="ReadOnlyModeBanner">
        <div className="read-only-mode-banner__content">
          <EuiIcon
            type="lock"
            size="m"
          />
          <p>App Search is currently in read-only mode. You will be unable to make changes such as creating, editing, or deleting.</p>
        </div>
      </div>
      <div className="read-only-mode-banner-margin" />
    </>
  )
}

export default ReadOnlyModeBanner
