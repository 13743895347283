import React from 'react'

import { EuiSpacer } from '@elastic/eui'

import Header from 'app_search/Layout/Header'
import StuiHeading from 'stui/Heading'

import AnalyticsChart from 'app_search/Analytics//AnalyticsChart'

interface IEngineAnalyticsProps {
  label: string
  title: string
  chartButton: React.ReactNode
  data: any
  startDate: Date
  endDate: Date
}

const EngineAnalytics: React.SFC<IEngineAnalyticsProps> = ({ title, label, data, chartButton, startDate, endDate }) => (
  <>
    <Header contained={true} key="header" actions={chartButton}>
      <StuiHeading type="title">Total {title}</StuiHeading>
      <p>Last 7 days</p>
    </Header>
    <EuiSpacer />
    <AnalyticsChart
      data={data}
      labels={[label]}
      startDate={startDate}
      endDate={endDate}
    />
  </>
)

export default EngineAnalytics
