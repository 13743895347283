import React, { useState } from 'react'

import { EuiModalBody } from '@elastic/eui'

import Header from 'app_search/Layout/Header'
import { IObject } from 'app_search/types'
import StuiDropzone from 'stui/Dropzone'
import StuiHeading from 'stui/Heading'
import StuiIcon from 'stui/Icon'

import DocumentCreationPanelFooter from './DocumentCreationPanelFooter'

interface IDocumentCreationDropzoneProps {
  errors: string[]
  uploading: boolean
  submitFile(files: IObject[])
  toggleDocumentCreation()
}

const DocumentCreationDropzone: React.SFC<IDocumentCreationDropzoneProps> = ({ errors, uploading, submitFile, toggleDocumentCreation }) => {
  const [state, setNewState] = useState({ files: [] })
  const setState = newState => setNewState({ ...state, ...newState })

  const { files } = state

  const handleOnDrop = droppedFiles => setState({ files: droppedFiles })

  const handleSubmit = e => {
    e.preventDefault()
    if (files.length === 0) { return }
    const reader: any = new FileReader()
    reader.onload = event => submitFile(event.target.result)
    reader.readAsText(files[0])
  }

  const errorsContainer = errors.length > 0 &&
    <div className="c-stui-code-block__error">
      <StuiIcon name="error-fill" />
      <Header>
        <StuiHeading type="title">Invalid input</StuiHeading>
        {errors.map((errorMessage, index) => <p key={index}>{errorMessage}</p>)}
      </Header>
    </div>

  return (
    <>
      <EuiModalBody>
        <StuiDropzone
          title="Drag and drop .json"
          description="If you have a .json file, drag and drop or upload it. Ensure the JSON is valid and that each document object is less than 102400 bytes."
          fileTypes={['application/json']}
          onDrop={handleOnDrop}
        />
        {errorsContainer}
      </EuiModalBody>
      <DocumentCreationPanelFooter
        uploading={uploading}
        handleSubmit={handleSubmit}
        handleClose={toggleDocumentCreation}
      />
    </>
  )
}

export default DocumentCreationDropzone
