import React, { useEffect } from 'react'

import {
  EuiButton,
  EuiCallOut,
  EuiCheckbox,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFormRow,
  EuiPanel,
  EuiRadio,
  EuiSpacer
} from '@elastic/eui'
import {
  useActions,
  useValues
} from 'kea'
import { Link, withRouter } from 'react-router-dom'

import { Role } from 'app_search/classes'
import { Loading } from 'app_search/components/Loading'
import Header from 'app_search/Layout/Header'
import { IMatch, IObject } from 'app_search/types'
import { USERS_PATH } from 'app_search/utils/routePaths'
import StuiFlashMessages from 'stui/FlashMessages'
import StuiHeading from 'stui/Heading'
import StuiMain from 'stui/Main'

import {
  IFlashMessagesActions,
  FlashMessagesLogic,
  IFlashMessagesValues
} from './FlashMessagesLogic'
import {
  IUserActions,
  IUserValues,
  UserLogic
} from './UserLogic'

interface IUserProps {
  history: IObject
  match: IMatch
}

const User: React.SFC<IUserProps> = ({
  history,
  match
}) => {
  const {
    handleRemoveUser,
    handleSaveUser,
    initializeUserData,
    selectFullEngineAccess,
    selectLimitedEngineAccess,
    selectRoleType,
    setSelectedEngineNames
  } = useActions(UserLogic) as IUserActions

  const {
    dataLoading,
    engineAccessEnabled,
    initialEngineNames,
    selectedAccessAllEngines,
    selectedEngineNames,
    selectedRoleType,
    userData
  } = useValues(UserLogic) as IUserValues

  const { loadQueuedMessages } = useActions(FlashMessagesLogic) as IFlashMessagesActions

  const { flashMessages } = useValues(FlashMessagesLogic) as IFlashMessagesValues

  useEffect(() => {
    initializeUserData(match.params.roleId)
    loadQueuedMessages()
  }, [])

  if (dataLoading) { return <Loading /> }

  const myRole = new Role(userData.myRole)

  const viewActions = myRole.ability.canChangeRole(userData.user.role) && (
    <EuiButton
      color="secondary"
      fill={true}
      onClick={(e) => handleSaveUser(e)}
    >
      Save Changes
    </EuiButton>
  )

  const removeUser = myRole.ability.canRemoveRole(userData.user.role) && (
    <EuiCallOut
      color="danger"
      title="Remove User"
    >
      <p>Warning! This action cannot be undone.</p>
      <EuiButton
        color="danger"
        fill={true}
        href="#"
        onClick={(e) => handleRemoveUser(e, history)}
        data-confirm={`Are you sure you want to remove the following user? ${userData.user.email}`}
      >
        Remove User
      </EuiButton>
    </EuiCallOut>
  )

  const roleSelector = (roleType, description) => {
    return (
      <EuiFormRow>
        <EuiRadio
          disabled={!myRole.ability.canChangeRoleTo(userData.user.role, roleType)}
          id={roleType}
          checked={roleType === selectedRoleType}
          onChange={() => {
            selectRoleType(roleType)
          }}
          label={
            <Header>
              <StuiHeading className="users-layout__users--roletype" type="title">{roleType}</StuiHeading>
              <p>{description}</p>
            </Header>
          }
        />
      </EuiFormRow>
    )
  }

  const engineSelector = (engine) => {
    const engineName = engine.name
    const elId = `engine_${engineName}`
    return <EuiCheckbox
      key={engineName}
      name={engineName}
      id={elId}
      checked={selectedEngineNames.has(engineName)}
      onChange={(e) => {
        const newSelectedEngineNames = new Set(selectedEngineNames as Set<string>)
        if (e.target.checked) {
          newSelectedEngineNames.add(engineName)
        } else {
          newSelectedEngineNames.delete(engineName)
        }
        setSelectedEngineNames(newSelectedEngineNames)
      }}
      label={engineName}
    />
  }

  const engineAccessDisabled = !myRole.ability.canChangeRole(userData.user.role) || !engineAccessEnabled
  const availableEngines = userData.availableEngines.filter(e => e.type !== 'meta')
  const availableMetaEngines = userData.availableEngines.filter(e => e.type === 'meta')

  const advancedRoleSelectors = <>
    <EuiSpacer />
    <Header>
      <StuiHeading type="title">Full or Limited Engine Access</StuiHeading>
    </Header>
    <EuiSpacer />
    {roleSelector('dev', 'Devs can manage all aspects of an Engine.')}
    {roleSelector('editor', 'Editors can manage Search Settings.')}
    {roleSelector('analyst', 'Analysts can only view Documents, Query Tester, and Analytics.')}
    {!process.env.LOCO_TOGO && <>
      <EuiSpacer />
      <Header>
        <StuiHeading type="title">No Engine Access</StuiHeading>
      </Header>
      <EuiSpacer />
      {roleSelector('finance', 'Finance users can only manage billing details.')}
    </>}
  </>

  return (
    <>
      <div className="stui-view-topbar">
        <div className="o-stui-breadcrumbs">
          <Link className="o-stui-breadcrumb" to={USERS_PATH}>Users</Link>
          <div className="o-stui-breadcrumb">Manage User</div>
        </div>
      </div>
      <Header contained={true} viewHeader={true} actions={viewActions}>
        <StuiHeading type="view">{userData.user.name}</StuiHeading>
        <p>{userData.user.email}</p>
      </Header>
      <StuiMain>
        <StuiFlashMessages {...flashMessages} />
        <EuiFlexGroup alignItems="stretch">
          <EuiFlexItem>
            <EuiPanel paddingSize="l">
              <Header>
                <StuiHeading type="section">Role</StuiHeading>
              </Header>
              <EuiSpacer />

              <Header>
                <StuiHeading type="title">Full Engine Access</StuiHeading>
              </Header>
              <EuiSpacer />
              {roleSelector('owner', 'Owners can do anything, which includes changing billing details and closing the account. There can be many owners on the account, but there must be at least one owner at any time.')}
              {roleSelector('admin', 'Admins can do anything, except modify billing information and close the account.')}

              {userData.hasAdvancedRoles && advancedRoleSelectors}
            </EuiPanel>
          </EuiFlexItem>
          {userData.hasAdvancedRoles && <EuiFlexItem>
            <EuiPanel paddingSize="l">
              <Header>
                <StuiHeading type="section">Engine Access</StuiHeading>
              </Header>
              <EuiSpacer />
              <EuiFormRow>
                <EuiRadio
                  disabled={engineAccessDisabled}
                  name="engine"
                  id="engine_1"
                  value="true"
                  checked={!!selectedAccessAllEngines}
                  onChange={() => selectFullEngineAccess()}
                  label={
                    <Header>
                      <StuiHeading type="title">Full Engine Access</StuiHeading>
                      <p>Access to all current and future Engines.</p>
                    </Header>
                  }
                />
              </EuiFormRow>
              <EuiFormRow>
                <>
                  <EuiRadio
                    disabled={engineAccessDisabled}
                    name="engine"
                    id="engine_2"
                    value="true"
                    checked={!selectedAccessAllEngines}
                    onChange={() => selectLimitedEngineAccess(initialEngineNames)}
                    label={
                      <Header>
                        <StuiHeading type="title">Limited Engine Access</StuiHeading>
                        <p>Limit user access to specific engines:</p>
                      </Header>
                    }
                  />
                  {!selectedAccessAllEngines &&
                    <>
                      <div className="engines-list">
                        {availableEngines.map((engine) => engineSelector(engine))}
                        {availableMetaEngines.length > 0 &&
                          <>
                            <EuiSpacer />
                            <Header>
                              <StuiHeading type="title">Meta-Engine Access</StuiHeading>
                              <p className="meta-engines-sub-title">Users will also have read access to the documents in connected Engines through Meta Engine access.</p>
                            </Header>
                            <EuiSpacer />
                            {availableMetaEngines.map((engine) => engineSelector(engine))}
                          </>
                        }
                      </div>
                    </>
                  }
                </>
              </EuiFormRow>
            </EuiPanel>
          </EuiFlexItem>
          }
        </EuiFlexGroup>
        <EuiSpacer size="xl" />
        {removeUser}
      </StuiMain>
    </>
  )
}

export default withRouter(User)
