import React, { useState } from 'react'

import jsonlint from 'jsonlint-mod'
import _chunk from 'lodash/chunk'
import _isPlainObject from 'lodash/isPlainObject'
import _uniq from 'lodash/uniq'

import routes from 'app_search/routes'
import { IIndexSummary, IObject } from 'app_search/types'
import http from 'shared/http'

import {
  EuiModal,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiOverlayMask
} from '@elastic/eui'

import DocumentCreationDropzone from './DocumentCreationDropzone'
import DocumentCreationError from './DocumentCreationError'
import DocumentCreationSummary from './DocumentCreationSummary'
import DocumentCreationTextarea from './DocumentCreationTextarea'

const ADD_DOCUMENTS = 1
const SHOW_ERROR_SUMMARY = 2
const SHOW_SUCCESS_SUMMARY = 3
const SHOW_ERROR = 4

const CHUNK_SIZE = 100
const MAX_DOCUMENTS = 5000
const MAX_EXAMPLES = 5

const defaultState = {
  errors: [],
  sectionStep: 1,
  responseData: {},
  textInput: '',
  uploading: false
} as IDocumentCreationState

interface IDocumentCreationProps {
  engineName: string
  creationMode: string
  onSuccess()
  toggleDocumentCreation()
}

interface IDocumentCreationState {
  sectionStep: number
  responseData: IObject
  errors: string[]
  textInput: string
  uploading: boolean
}

const DocumentCreation: React.SFC<IDocumentCreationProps> = ({
  engineName,
  creationMode,
  onSuccess,
  toggleDocumentCreation
}) => {
  const [state, setNewState] = useState(defaultState)
  const setState = newState => setNewState({ ...state, ...newState })

  const {
    sectionStep,
    responseData,
    errors,
    textInput,
    uploading
  } = state

  const handleFixDocuments = () => setState({ sectionStep: ADD_DOCUMENTS })

  const parseJSON = (input) => {
    let documents
    try {
      documents = jsonlint.parse(input)
    } catch (error) {
      setState({ errors: [error.message], textInput: input })
      return
    }
    if (Array.isArray(documents) && documents.length > MAX_DOCUMENTS) {
      setState({ errors: [`Too many documents to create (${documents.length}). Must be less than ${MAX_DOCUMENTS}.`], textInput: input })
      return
    }

    if (Array.isArray(documents)) { return documents }
    if (_isPlainObject(documents)) { return [documents] }

    setState({
      errors: ['Document contents must be a valid JSON array or object.'],
      textInput: input
    })
  }

  const handleSubmitText = (input) => {
    const documents = parseJSON(input)
    if (!documents) { return }

    setState({ uploading: true })
    handleSubmit(documents, { textInput }, true)
  }

  const setSummaryState = (summary, newState) => {
    if (summary.errors.length > 0) {
      setState({ errors: summary.errors })
      return
    }
    if (summary.invalidDocuments.total === 0) {
      setState({
        responseData: summary,
        sectionStep: SHOW_SUCCESS_SUMMARY,
        uploading: false
      })
      return
    }
    setState({
      errors: [],
      sectionStep: SHOW_ERROR_SUMMARY,
      responseData: summary,
      uploading: false,
      ...newState
    })
  }

  const handleSubmit = (documents, newState, dryRun) => {
    const promises = _chunk(documents, CHUNK_SIZE).map((documentsChunk) => {
      return http.post(routes.createLocoMocoEngineDocumentsPath(engineName), { documents: documentsChunk, dry_run: dryRun })
    })

    Promise.all(promises).then((responses) => {
      const summary: any = {
        errors: [],
        validDocuments: { total: 0, examples: [] },
        invalidDocuments: { total: 0, examples: [] },
        newSchemaFields: []
      }
      responses.map((response: any) => {
        const data: any = response.data
        if (data.errors && data.errors.length > 0) {
          summary.errors = _uniq([...summary.errors, ...data.errors])
          return
        }
        summary.validDocuments.total += data.validDocuments.total
        summary.invalidDocuments.total += data.invalidDocuments.total
        summary.validDocuments.examples = [...summary.validDocuments.examples, ...data.validDocuments.examples].slice(0, MAX_EXAMPLES)
        summary.invalidDocuments.examples = [...summary.invalidDocuments.examples, ...data.invalidDocuments.examples].slice(0, MAX_EXAMPLES)
        summary.newSchemaFields = _uniq([...summary.newSchemaFields, ...data.newSchemaFields])
      })

      if (!dryRun && summary.errors.length === 0 && summary.invalidDocuments.total === 0) {
        handleSubmit(documents, newState, false)
      } else {
        setSummaryState(summary, newState)
      }
    }).catch(({ message, response }) => {
      setState({
        sectionStep: SHOW_ERROR,
        errors: (response && response.data && (response.data.errors || [response.data.error])) || message
      })
    })
  }

  return (
    <EuiOverlayMask>
      <EuiModal onClose={toggleDocumentCreation}>
        <EuiModalHeader>
          <EuiModalHeaderTitle>Document Import</EuiModalHeaderTitle>
        </EuiModalHeader>
        {(sectionStep === SHOW_ERROR) &&
          <DocumentCreationError
            errors={errors}
            buttonTitle="OK"
            buttonClick={toggleDocumentCreation}
          />}
        {(sectionStep === ADD_DOCUMENTS && creationMode === 'manual') &&
          <DocumentCreationTextarea
            value={textInput}
            toggleDocumentCreation={toggleDocumentCreation}
            submitText={handleSubmitText}
            errors={errors}
            uploading={uploading}
          />}
        {(sectionStep === ADD_DOCUMENTS && creationMode === 'import') &&
          <DocumentCreationDropzone
            toggleDocumentCreation={toggleDocumentCreation}
            submitFile={handleSubmitText}
            errors={errors}
            uploading={uploading}
          />}
        {sectionStep === SHOW_ERROR_SUMMARY && <DocumentCreationSummary
          buttonClick={handleFixDocuments}
          buttonTitle="Fix Errors"
          description="Something went wrong. Please address the errors and try again."
          indexSummary={responseData as IIndexSummary}
        />}
        {sectionStep === SHOW_SUCCESS_SUMMARY && <DocumentCreationSummary
          buttonClick={onSuccess}
          buttonTitle="Close"
          description=""
          indexSummary={responseData as IIndexSummary}
        />}
      </EuiModal>
    </EuiOverlayMask>
  )
}

export default DocumentCreation
