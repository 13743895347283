import React from 'react'

import { truncate, truncateBeginning } from 'app_search/utils'

interface IStuiTruncatedContentProps {
  content: string
  length: number
  beginning?: boolean
  tooltipType?: 'inline' | 'title'
}

const StuiTruncatedContent: React.SFC<IStuiTruncatedContentProps> = ({
  content = '',
  length,
  beginning = false,
  tooltipType = 'inline'
}) => {
  if (content.length <= length) {
    return <>{content}</>
  }

  const inline = tooltipType === 'inline'
  return (
    <span className="o-stui-truncated-content" title={!inline ? content : ''}>
      {beginning ? truncateBeginning(content, length) : truncate(content, length)}
      {inline && <span className="o-stui-truncated-content__tooltip">{content}</span>}
    </span>
  )
}

export default StuiTruncatedContent
