import React from 'react'
import { withRouter } from 'react-router-dom'

import classNames from 'classnames'

import { CurationsLogic } from './CurationsLogic'

import { ICurationQueryItem, IObject } from 'app_search/types'

import {
  EuiButton,
  EuiButtonEmpty,
  EuiFieldSearch
} from '@elastic/eui'

import StuiIcon from 'stui/Icon'
import StuiLoader from 'stui/Loader'

const MAX_LENGTH = 64
const ENTER = 13

interface IStuiCurationQueryProps {
  actions: {
    deleteQueryItem(index: number)
    resetCurationQueries()
    saveCurationQueries(inputVisible: boolean, index: number)
    setQueryItemInputVisibility(hideInput: boolean, index: number)
    handleCurationQueryBlur(text: string, index: number)
    updateQueryItemText(text: string, index: number)
    setActiveQuery(text: string)
    submitCurationSet(history: IObject)
  }
  curationQuery: ICurationQueryItem
  focused?: boolean
  canActivate?: boolean
  isNewCuration?: boolean
  index: number
  isActive: boolean
  canDelete: boolean
  canEdit: boolean
  history: IObject
}

class CurationQuery extends React.Component<IStuiCurationQueryProps> {
  render() {
    const {
      actions: {
        deleteQueryItem,
        resetCurationQueries,
        saveCurationQueries,
        setQueryItemInputVisibility,
        handleCurationQueryBlur,
        updateQueryItemText,
        setActiveQuery,
        submitCurationSet
      },
      index,
      isNewCuration = false,
      canActivate,
      canEdit,
      canDelete,
      isActive,
      curationQuery,
      focused,
      history
    } = this.props
    const cardClassNames = classNames('curation-query-card', {
      'curation-query-card--active': isActive,
      'curation-query-card--can-activate': canActivate
    })
    const valueClassNames = classNames('curation-query-card__query-value', { 'curation-query-card__query-value--can-activate': canActivate })

    const handleEditClick = event => {
      event.stopPropagation()
      setQueryItemInputVisibility(false, index)
    }

    const handleDeleteClick = event => {
      event.stopPropagation()
      deleteQueryItem(index)
    }

    const handleKeyDown = event => {
      if (event.which === ENTER) {
        event.preventDefault()
        submitCurationSet(history)
      }
    }

    const handleFormSubmit = event => {
      event.preventDefault()
      if (!isNewCuration) {
        saveCurationQueries(true, index)
      }
    }

    const disabled = curationQuery.text.length < 1

    const curationQueryInput = (
      <form className="curation-query-input inline-form-container" onSubmit={handleFormSubmit}>
        <EuiFieldSearch
          value={curationQuery.text}
          maxLength={MAX_LENGTH}
          placeholder="Enter a query"
          autoFocus={focused}
          onKeyDown={isNewCuration ? handleKeyDown : undefined}
          onBlur={(e) => handleCurationQueryBlur(e.target.value, index)}
          onChange={(e) => updateQueryItemText(e.target.value, index)}
          fullWidth={true}
          data-test-subj="EnterQueryCurationsInput"
        />
        {!isNewCuration && <>
          <EuiButton
            fill={true}
            disabled={disabled}
            type="submit"
          >
            Save
          </EuiButton>
          <EuiButtonEmpty onClick={resetCurationQueries}>Cancel</EuiButtonEmpty>
        </>}
      </form>
    )

    const curationQueryCard = (
      <div className={cardClassNames} onClick={!canActivate ? undefined : () => setActiveQuery(curationQuery.text)}>
        {isActive &&
          <div className="curation-query-card__icon">
            <StuiIcon name="success-fill" />
          </div>
        }
        <div className={valueClassNames}>
          <div className="curation-query-card__query-value">{curationQuery.text}</div>
          <div className="curation-query-card__query-count">
            {curationQuery.hasOwnProperty('count') ?
              `${curationQuery.count} result${curationQuery.count === 1 ? '' : 's'}` :
              <div className="o-stui-loading-message"><StuiLoader size="m" /> Loading...</div>
            }
          </div>
        </div>
        <div className="curation-query-card__actions">
          {canEdit && <button onClick={e => handleEditClick(e)}><StuiIcon name="edit" /></button>}
          {canDelete && <button onClick={e => handleDeleteClick(e)} data-test-subj="ManageQueriesDeleteCurationButton"><StuiIcon name="delete" /></button>}
        </div>
      </div>
    )
    return curationQuery.hideInput ? curationQueryCard : curationQueryInput
  }
}

export default withRouter(CurationsLogic(CurationQuery))
